//import logo from './logo.svg';
import { Route, Router } from "wouter";
import React, { useState, useEffect } from 'react';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import "@fontsource/quicksand";
import './App.css';

import Login from "./pages/StaticPages/Login"
import Signup from "./pages/StaticPages/Signup"
import Home from "./pages/StaticPages/PageLanding"
import Games from "./pages/Games"
import ClickPickAI from "./pages/games/ClickPickAI";
import GamePage from "./pages/GamePage"
import Dashboard from "./pages/StaticPages/Dashboard"
import { useTranslation } from 'react-i18next';
import AboutUs from "./pages/StaticPages/AboutUs";
import ContactUs from "./pages/StaticPages/ContactUs";
import GameChoose from "./pages/GameChoose";
import PlayGame from "./pages/PlayGame";
import ClickPick from "./pages/games/ClickPick";
import Layout from "./pages/StaticPages/Layout";
import ClickPickCreate from "./pages/games/ClickPickCreate";
import AdministratorGames from "./pages/StaticPages/AdministratorGames";
import MyActivities from "./pages/MyActivities";
function App() {
  const [embed, setIsEmbed] = useState(false);
  const { i18n } = useTranslation();
  useEffect(() => {
    document.body.dir = i18n.language === 'ar' ? 'rtl' : 'ltr';

  }, [i18n.language]);
  const theme = createTheme({

    typography: {
      fontFamily: i18n.language === 'ar' ? 'tajawal': 'Quicksand',
      direction: i18n.language === 'ar' ? 'rtl' : 'ltr', // RTL for Arabic

      components: {
        MuiCssBaseline: {
          styleOverrides: `
            html {
              -webkit-text-size-adjust: 100%;
              text-size-adjust: 100%;
            }
          `,
        },
      },
   
    
    },

});

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
{/* <I18nextProvider i18n={i18n}></I18nextProvider> */}
    <Router>
      <Layout>
    <Route path="/" component={Home} />
    <Route path="/about" component={AboutUs} />
    <Route path="/contact" component={ContactUs} />

    <Route path="/Games" component={Games} />
    <Route path="/GameSelect/:id" component={GameChoose} />
    
    <Route path="/admin" component={AdministratorGames} />
    <Route path="/PlayGame/:id" component={PlayGame} />
    <Route path="/ClickPickCreate" component={ClickPickCreate} />
    <Route path="/ClickPickCreate/:id" component={ClickPickCreate} />
    <Route path="/ClickPickAI" component={ClickPickAI} />

    <Route path="/MyActivities" component={MyActivities} />
    <Route path="/signup" component={Signup} />
    <Route path="/createGame" component={GamePage} />
    <Route path="/Dashboard" component={Dashboard} /> 
    <Route path="/gamepage" component={GamePage} /> 
    <Route path="/play/ClickPick/:id" component={ClickPick} />

    <Route path="/embed/ClickPick/:id" component={ClickPick} />
    </Layout>
    </Router>

    <Router>
    

    </Router>
    </ThemeProvider>
  );
}

export default App;
