import React, { useEffect, useState } from 'react';
import '../Styles.css';
import axios from 'axios';
import gamesData from '../reusables/gamesData';
import { Button,TextField,
    Card,
    CardContent,
    MenuItem,
    Select,
    Paper,
    Checkbox, Dialog, DialogTitle, DialogContent, Grid, Box, Typography, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useLocation } from 'wouter';

import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import ShareIcon from "@mui/icons-material/Share";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTranslation } from 'react-i18next';
const Dashboard = () => {
    const [games, setGames] = useState([]);
    const [location, setLocation] = useLocation();
    const [isLoggedIn, setIsLoggedIn] = useState(false); // Change this based on your auth logic
    const [username, setUsername] = useState('');
    const {t, i18n} = useTranslation();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedGames, setSelectedGames] = useState([]);
    const [page, setPage] = useState('Games');
    const [open, setOpen] = useState(false); // Change to `false` initially

    const handleNav = () => {
        if(open)
            setOpen(false);
        else 
            setOpen(true);
    };
  
    useEffect(() => {
        // Example of getting auth status from localStorage (or any other method)
        const token = localStorage.getItem('access_token');
        const storedUsername = localStorage.getItem('first_name');

        if (!token || !storedUsername) {
            setLocation('/'); // Redirect to the homepage or login
        } else {
            setIsLoggedIn(!!token);
            setUsername(storedUsername || '');
        }
        fetchGames();
    }, []);
 
      const fetchGames = async () => {
        try {
          const response = await fetch(`https://qnar.fun/api/games/user/${localStorage.getItem('username')}/`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
          });
      
          // Check if the response is successful
          if (!response.ok) {
            throw new Error(`Failed to fetch games: ${response.statusText}`);
          }
      
          // Parse the response JSON
          const data = await response.json();
          setGames(data);
          console.log('Fetched games:', data);
          return data;
        } catch (error) {
          console.error('Error fetching games:', error);
          return null; // Return null or handle error appropriately
        }
      };


      const handleEdit = (gameId, gameType) => {
        if (gameType == 1){}
        else if (gameType == 2){
        setLocation(`/ClickPickCreate/${gameId}`)}
        console.log(`Edit game with ID: ${gameId}`);
        // Navigate to edit page or open edit modal
    };
    
    const handleShare = (gameId) => {
        console.log(`Share game with ID: ${gameId}`);
        // Trigger sharing functionality
    };
    
    const handleDelete = async (gameId) => {
        const confirmDelete = window.confirm("Are you sure you want to delete this game?");
        if (confirmDelete) {
            try {
                const token = localStorage.getItem('access_token'); // Ensure this is where you store your JWT token
        if (!token) {
            console.log("No token found!");
            return;
        }

        const response = await fetch(`https://qnar.fun/api/games/${gameId}/delete/`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`, // Pass token here
            },
        });

        if (!response.ok) {
            const data = await response.json();
            console.error("Error deleting game:", data);
            return;
        }

        const data = await response.json();
        console.log("Game deleted:", data);
        // Update state to reflect deleted game (if applicable)
        setGames((prevGames) => prevGames.filter((game) => game.id !== gameId));
    } catch (error) {
        console.error("Error deleting game:", error);
    }
    };}
    
    const handleShowGames = (gameId, gameType) => {
        if (gameType == 2){
            setLocation(`/play/clickpick/${gameId}`)
        }
        
        // Fetch and display game details or navigate
    };
    
    const handleAddBundle = () => {
        console.log("Add a new bundle");
        // Open bundle creation modal or navigate
    };
    
 
    return (<>
        <Box 
  p={3} 
  maxWidth="100%" 
  mx="auto" 
  minHeight="100vh" /* Ensures the content occupies the full viewport height */
  display="flex" 
  flexDirection="column" 
  justifyContent="center" 
  alignItems="center" 
  backgroundColor="#F5FFF8"
  color={"#3B5D44"}
>
            {/* Header */}
            <Box display="flex" width="80%" justifyContent="space-between" alignItems="center" mb={3}>
                <Box>
                    <Typography variant="h3">Welcome, {username}</Typography>
                    <Typography variant="subtitle1" fontWeight={"bold"} >
                        We hope you enjoy your journey with Qnar
                    </Typography>
                </Box>
                <Button
  variant="outlined"
  sx={{
    backgroundColor: 'white',
    color: '#3B5D44', // Text color
    border: '2px solid #3B5D44', // Border color
    '&:hover': {
      backgroundColor: '#f0f0f0', // Light gray background on hover
      border: '2px solid #3B5D44', // Border color remains the same on hover
      fontWeight: "bold"
    },
  }}
  onClick={() => handleNav()}
>
  Create New Activity
</Button>
            </Box>

            {/* Toolbar */}
            <Paper sx={{
                width: '80%',
                backgroundColor: 'white',
                mx: 'auto',
                p: 2
            }}>
                <Grid container alignItems="center" spacing={2} mb={3}>
                    <Grid item xs={12} sm={6} md={4}>
                    <Button
  variant={page === 'Games' ? 'contained' : 'outlined'}
  onClick={() => setPage('Games')}
  sx={{
    width: '150px',
    ml: 2,
    backgroundColor: page === 'Games' ? '#3B5D44' : 'white', // Green background if "Games"
    color: page === 'Games' ? 'white' : '#3B5D44', // White text if "Games"
    border: page === 'Games' ? '2px solid #3B5D44' : '2px solid #3B5D44', // Same border color for both
    '&:hover': {
      backgroundColor: page === 'Games' ? '#2c4b3b' : '#f0f0f0', // Darker green on hover for "Games", light gray for others
      border: '2px solid #3B5D44', // Border color remains same on hover
      fontWeight: 'bold',
    },
  }}
>
  My Games
</Button>

{/* <Button
  variant={page === 'Bundles' ? 'contained' : 'outlined'}
  onClick={() => setPage('Bundles')}
  sx={{
    ml: 2,
    backgroundColor: page === 'Bundles' ? '#3B5D44' : 'white', // Green background if "Bundles"
    color: page === 'Bundles' ? 'white' : '#3B5D44', // White text if "Bundles"
    border: page === 'Bundles' ? '2px solid #3B5D44' : '2px solid #3B5D44', // Same border color for both
    width: '150px',
    '&:hover': {
      backgroundColor: page === 'Bundles' ? '#2c4b3b' : '#f0f0f0', // Darker green on hover for "Bundles", light gray for others
      border: '2px solid #3B5D44', // Border color remains same on hover
      fontWeight: 'bold',

    },
  }}
>
  My Bundles
</Button> */}

                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Select defaultValue="default" fullWidth>
                            <MenuItem value="default">Sort By</MenuItem>

                            <MenuItem value="Name">Name</MenuItem>
                            <MenuItem value="Modified">Modified</MenuItem>
                            <MenuItem value="Last Played">Last Played</MenuItem>
                        </Select>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                        <TextField
                            fullWidth
                            placeholder="Search My Activity"
                            variant="outlined"
                        />
                    </Grid>
                </Grid>

                {/* Activity Bundles */}
                {page === "Bundles" ?
                    <Grid container spacing={3}>
                        {/* Example Bundle Card */}
                        <Grid item xs={12} sm={6} md={4}>
                            <Card>
                                <CardContent>
                                    <Typography variant="h6">Bundle Title</Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        Unit
                                    </Typography>
                                    <Box mt={2} display="flex" justifyContent="space-between">
                                        <IconButton color="primary">
                                            <EditIcon />
                                        </IconButton>
                                        <IconButton color="secondary">
                                            <ShareIcon />
                                        </IconButton>
                                        <IconButton color="error">
                                            <DeleteIcon />
                                        </IconButton>
                                        <Button size="small" variant="contained">
                                            Show Games
                                        </Button>
                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid>

                        {/* Another Bundle Card */}
                        <Grid item xs={12} sm={6} md={4}>
                            <Card>
                                <CardContent>
                                    <Typography variant="h6">Bundle Title</Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        Unit
                                    </Typography>
                                    <Box mt={2} display="flex" justifyContent="space-between">
                                        <IconButton color="primary">
                                            <EditIcon />
                                        </IconButton>
                                        <IconButton color="secondary">
                                            <ShareIcon />
                                        </IconButton>
                                        <IconButton color="error">
                                            <DeleteIcon />
                                        </IconButton>
                                        <Button size="small" variant="contained">
                                            Show Games
                                        </Button>
                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid>

                        {/* Add Bundle Button */}
                        <Grid item xs={12} sm={6} md={4}  onClick={() => setLocation('games/2')}> 
                            <Card
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    height: "100px",
                                    border: "2px dashed #4caf50",
                                    cursor: "pointer",
                                }}
                               
                                >
                                <AddIcon fontSize="large" color="success" />
                            </Card>
                        </Grid>
                    </Grid>

                    :
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////                    
                    <Grid container spacing={3}>
                    {/* Map over games array to dynamically create cards */}
                    {games.map((game) => (
                        <Grid item xs={12} sm={6} md={4} key={game.id}>
                            <Card>
                                <CardContent>
                                <Checkbox
                checked={selectedGames.includes(game.id)}
                onChange={(e) => {
                    const isChecked = e.target.checked;
                    setSelectedGames((prev) =>
                        isChecked ? [...prev, game.id] : prev.filter((id) => id !== game.id)
                    );
                }}
            />
                                    <Typography variant="h6">{game.title}</Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        {game.description}
                                    </Typography>
                                    <Typography variant="caption" color="text.secondary" display="block">
                                        Created At: {new Date(game.created_at).toLocaleDateString()}
                                    </Typography>
                                    <Box mt={2} display="flex" justifyContent="space-between">
                                        <IconButton
                                            color="primary"
                                           onClick={() => handleEdit(game.id, game.gameType)}
                                        >
                                            <EditIcon />
                                        </IconButton>
                                        <IconButton
                                            color="secondary"
                                           onClick={() => handleShare(game.id)}
                                        >
                                            <ShareIcon />
                                        </IconButton>
                                        <IconButton
                                            color="error"
                                            onClick={() => handleDelete(game.id)}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                        <Button
                                            size="small"
                                            variant="contained"
                                           onClick={() => handleShowGames(game.id, game.gameType)}
                                        >
                                            Show Game
                                        </Button>
                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                
                    {/* Add Bundle Button */}
                    <Grid item xs={12} sm={6} md={4} onClick={() => handleNav()}>
                        <Card
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "100%", // Match the height of other cards

                                border: "2px dashed #4caf50",
                                cursor: "pointer",
                            }}
                           // onClick={handleAddBundle}
                        >
                            <AddIcon fontSize="large" color="success" />
                        </Card>
                    </Grid>
                </Grid>}
                <Button
                sx={{marginTop: "20px"}}
    variant="contained"
    color="error"
    disabled={selectedGames.length === 0}
    onClick={async () => {
        const confirmDelete = window.confirm(
            `Are you sure you want to delete ${selectedGames.length} game(s)?`
        );
        if (confirmDelete) {
            try {
                const token = localStorage.getItem("access_token");
                if (!token) {
                    console.log("No token found!");
                    return;
                }

                await Promise.all(
                    selectedGames.map(async (gameId) => {
                        const response = await fetch(`https://qnar.fun/api/games/${gameId}/delete/`, {
                            method: "DELETE",
                            headers: {
                                "Content-Type": "application/json",
                                Authorization: `Bearer ${token}`,
                            },
                        });
                        if (!response.ok) {
                            const data = await response.json();
                            console.error(`Error deleting game ${gameId}:`, data);
                            throw new Error(`Failed to delete game with ID: ${gameId}`);
                        }
                    })
                );

                // Remove deleted games from state
                setGames((prevGames) =>
                    prevGames.filter((game) => !selectedGames.includes(game.id))
                );
                setSelectedGames([]); // Clear selection
            } catch (error) {
                console.error("Error deleting selected games:", error);
            }
        }
    }}
>
    Delete Selected
</Button>
            </Paper>

        </Box>
         <Dialog open={open} onClose={handleNav} maxWidth="md" fullWidth>
         <DialogTitle>
           <Typography variant="h5" sx={{ textAlign: "center", fontWeight: "bold", color: "#3B5D44" }}>
             Choose your game
           </Typography>
           <Typography variant="subtitle1" sx={{ textAlign: "center", color: "#3B5D44", mt: 1 }}>
             Select a template to start creating a game
           </Typography>
           <IconButton
             onClick={handleNav}
             sx={{
               position: "absolute",
               top: 16,
               right: 16,
               color: "#3B5D44",
             }}
           >
             <CloseIcon />
           </IconButton>
         </DialogTitle>
   
         <DialogContent>
           <Grid container spacing={3} justifyContent="center">
             {gamesData.map((item, index) => (
               <Grid item xs={12} sm={6} md={3} key={item} >
                 <Box
                   sx={{
                     height: 300,
                     border: "1px solid #D4D4D4",
                     borderRadius: 2,
                     padding: 2,
                     textAlign: "center",
                     cursor: "pointer",
                     "&:hover": { borderColor: "#3B5D44" },
                   }}
                   onClick={() => {
                    if (item.id == 1 ) {
                      setLocation('/wheelCreate');
                    } else if (item.id == 2) {
                      setLocation('/clickpickCreate/1');
                    }

                  }}
                 >
                   <Box
                     sx={{
                       width: "100%",
                       height: '150px',
                       
                       display: "flex",
                       alignItems: "center",
                       backgroundImage: `url(${item.image})`,
          backgroundSize: 'contain', // Cover the entire area
          backgroundPosition: 'center', // Center the image
          backgroundRepeat: 'no-repeat', 
                       justifyContent: "center",
                       mb: 2,
                     }}
                   >
                     {/* Placeholder for the game icon */}
             
                   </Box>
                   <Typography variant="h6" sx={{ fontWeight: "bold", color: "#3B5D44" }}>
                   {t(item.title)}
                   </Typography>
                   <Typography variant="body2" sx={{ color: "#9E9E9E" }}>
                   {t(item.description)}
                   </Typography>
                 </Box>
               </Grid>
             ))}
           </Grid>
   
           <Box
             sx={{
               mt: 3,
               bgcolor: "#F5B7B1",
               padding: 2,
               textAlign: "center",
               color: "#ffffff",
               borderRadius: "4px",
             }}
           >
             <Typography variant="body2">
             Learn and play! Pick a game and discover a fun way to boost your knowledge.</Typography>
           </Box>
         </DialogContent>
       </Dialog>
       </>
    );
};


export default Dashboard;