import wheel from '../../Assets/spinIcon.png';
import ClickPick from '../../Assets/clickpick2.png';

const gamesData = [
    // {
    //   id: 1,
    //   title: 'SpinningH1',
    //   description: 'SpinningH2', // Use `t` where you're using this data
    //   image: wheel,
    // },
    {
      id: 2,
      title: 'ClickPickTitle',
      description: 'ClickPickH2', // Use `t` where you're using this data
      image: ClickPick,
    },
    // Add more games as needed
  ];
  
  export default gamesData;