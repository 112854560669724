import React, { useEffect, useState } from 'react';
import '../Styles.css'; // Import your CSS file
import axios from 'axios';
import { Box, Button, TextField, Typography, Paper, Grid, MenuItem, Select, List, ListItem } from "@mui/material";
import { useLocation } from 'wouter';
import Loading from "../../pages/reusables/Loading";
import ClickPick from '../../Assets/clickpick2.png'
import { useTranslation } from 'react-i18next';

const ClickPickAI = () => {
    const [location, setLocation] = useLocation();
    const [paragraph, setParagraph] = useState(``);
    const [NumberOfQuestions, setNumberOfQuestions] = useState(0)
    const [questions, setQuestions] = useState([]);
    const [game, setGame] = useState(false); // Flag to track if questions are ready
    const [id, setID] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [username, setUsername] = useState('');
    const [language, setLanguage] = useState("");
    const [GameTitle, setGameTitle]= useState('')
    const [GameDescription, setDescription] = useState('');
    const {t, i18n} = useTranslation();
    

useEffect(() => {
        // Example of getting auth status from localStorage (or any other method)
        const token = localStorage.getItem('access_token');
        const storedUsername = localStorage.getItem('username');

        if (!token || !storedUsername) {
            setLocation('/'); // Redirect to the homepage or login
        } else {
            setUsername(storedUsername || '');
        }
    }, []);


const parseQuestionsToJson = (rawText) => {
  try {
    // Step 1: Validate and extract the text part
    if (!rawText || typeof rawText !== "string") {
      throw new Error("Invalid rawText input");
    }

    const cleanedText = rawText
      .replace(/^parts {\s*text: "/, "") // Remove the prefix
      .replace(/"\s*role: "model"$/, "") // Remove the suffix
      .trim();

    // Step 2: Split into individual questions using 'q\d+:'
    const questionBlocks = cleanedText.split(/q\d+:/).map((block, index) => {
      return index === 0 ? block.split("q")[1] : block; // Handle the first question
    });

    // Step 3: Parse each question and convert to JSON
    const questions = questionBlocks
      .filter((block) => block && block.trim()) // Filter out empty blocks
      .map((block, blockIndex) => {
        try {
          // Extract the question title (before the first 'a1(')
          const [title, ...answerParts] = block.split(/a\d+\(/);

          if (!title || answerParts.length === 0) {
            throw new Error(`Malformed question block: ${block}`);
          }

          // Extract answers
          const answers = answerParts.map((part) => {
            const match = part.match(/^(.+?)\)\((true|false)\)/);
            if (!match) {
              throw new Error(`Malformed answer part: ${part}`);
            }
            const [, answerText, isCorrect] = match;
            return {
              text: answerText.trim(),
              isCorrect: isCorrect === "true",
              image: null, // Defaulting to null
            };
          });

          return {
            title: title.trim(),
            answers,
          };
        } catch (innerError) {
          console.warn(`Error parsing block at index ${blockIndex}: ${innerError.message}`);
          return null; // Skip invalid question blocks
        }
      })
      .filter((question) => question !== null); // Remove invalid questions

    // Set parsed questions (if applicable)
    setQuestions(questions);
    setIsLoading(false);
    return questions;
  } catch (error) {
    console.error("Error parsing questions:", error);
    return [];
  }
};
const validateResponseFormat = (response) => {
  // Regex to validate the format (adjust based on your specific format)
  const regex = /.*q\d+: .+? a\d+\(.+?\)\(true|false\)(, a\d+\(.+?\)\(true|false\))* q\d+: .+? a\d+\(.+?\)\(true|false\)(, a\d+\(.+?\)\(true|false\))*.*/;
  return regex.test(response.trim());
};

const generateQuestions = async (paragraph) => {
  setIsLoading(true);
  if (/[\u0600-\u06FF]/.test(paragraph)) {
    setLanguage("Arabic");
  } else if (/^[a-zA-Z]+$/.test(paragraph)) {
    setLanguage("English");
}
  
  let response;
  let isValid = false;

  while (!isValid) {
    response = await getQuestions(paragraph); // Replace with the actual function to call your AI
    isValid = validateResponseFormat(response); // Validate the format

    if (!isValid) {
      console.warn("Invalid format received, regenerating...");
    }
  }

  parseQuestionsToJson(response);
  return response;


}
const getQuestions = async (paragraph) => {
  try {
      const response = await axios.get("https://qnar.fun/api/generate-questions/", {
          params: {
              paragraph:`Generate 5 multiple-choice questions with 4–6 answers each. Include 2–5 correct answers per question. Format the output exactly like this: q1: Question text? a1(answer1)(true), a2(answer2)(false), a3(answer3)(true), a4(answer4)(false) q2: Question text? a1(answer1)(true), a2(answer2)(false), a3(answer3)(true), a4(answer4)(false) The text should always be on a single line, without line breaks, and follow the given structure. Use the topic: ${paragraph}.`
              //`Generate 5 multiple-choice questions with 4–6 answers each. Include 2–5 correct answers per question in format like  q1: a1(true), a2(false),a3(true) q2: etc... about ${paragraph}, all in one line`,
          },
      });

      if (response.status === 200) {

        return response.data.questions; // Return parsed content for further use
      }
    } catch (error) {
      console.error("Error fetching questions:", error);
    }
  };

    const postGame = async () => {
        try {
            const gameData = {
                gameType: 2,
                title: GameTitle,
                description: GameDescription,
                creator: username, // Make sure username is a string here
            };
    
          const response = await fetch('https://qnar.fun/api/games/', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(gameData),
          });
          const data = await response.json();
          return data.id; // Return the game ID for creating questions
        } catch (error) {
          console.error('Error posting game:', error);
        }
      };
    
      const postQuestion = async (questionData, gameId) => {
        try {
          const response = await fetch('https://qnar.fun/api/questions/', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },

            body: JSON.stringify({...questionData, game: gameId }),
          });
    
        } catch (error) {
          console.error('Error posting question:', error);
        }
      };
  
      const handleNext = async () => {
        if (!GameTitle || GameTitle.trim() === '') {
          alert('Please provide a game title.');
          return; // Prevent further action if the title is empty
        }
        const gameId = await postGame();
        for (const question of questions) {
            await postQuestion({ title: question.title, answers: question.answers }, gameId);
        }
        setLocation(`/play/ClickPick/${gameId}`);
      }

      const handleChange = (event) => {
        const newValue = event.target.value;
        setParagraph(newValue); // Update the paragraph state
    };


    return (
<Box 
  p={3} 
  maxWidth="100%" 
  mx="auto" 
  minHeight="100vh" /* Ensures the content occupies the full viewport height */
  display="flex" 
  flexDirection="column" 
  justifyContent="center" 
  alignItems="center" 
  backgroundColor="#F5FFF8"
  color={"#3B5D44"}
>
{isLoading ? (
        <Loading message="Generating Questions..."/>
      ) : <></>}
      <Grid container spacing={2} alignItems="center" sx={{ padding: '20px', flexDirection: {xs : "column", md : "row"}, }}>
      {/* Left Part */}
      <Grid
        item
        container
        xs={5}
        sx={{
          display: 'flex',
          flexWrap: { xs: 'wrap', md: 'nowrap'}, // Stack on small screens, align side-by-side on large screens
        }}
      >
        {/* Left: Image Section */}
        <Box
          sx={{
            flex: '0 auto',
            width: { xs: '50%', md: '100%' }, // Responsive width
            textAlign: 'center',
            marginBottom: { xs: '20px', md: '0' }, // Add spacing for smaller screens
         
          }}
        >
          <img
            src={ClickPick} // Replace with your image URL or variable
            alt="ClickPick"
            style={{
              maxWidth: '60%',
              height: 'auto',
              borderRadius: '8px', // Optional rounded corners
            }}
          />
        </Box>

        {/* Right: Text Section */}
        <Box
          sx={{
            flex: 'auto',
          //  paddingLeft: { md: '20px' }, // Add padding between image and text
            textAlign: 'left',
          }}
        >
          <Typography variant="h5" sx={{ fontWeight: 'bold', marginBottom: 2 }}>
          { t('ClickPickTitle')}
          </Typography>
          <Typography variant="h6" sx={{ marginBottom: 3 }}>
          {t('ClickPickH2')}
          </Typography>
        </Box>
      </Grid>
      {/* Right Part */}
      <Grid
        item
        xs={12}
        md ={4}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-end',
          justifyContent: 'flex-end',
          //padding: '20px',
        }}
      >
        <Button
          variant="contained"
          sx={{
            color: '#3B5D44',
            marginBottom: '20px',
            borderWidth: '2px',
            backgroundColor: '#F4DADA',
            borderColor: '#F4DADA',
            width: '250px',
            fontWeight: 'bold',
            fontSize: "1rem",
 
          }}
        >
          Create your own Questions
        </Button>
      </Grid>
    </Grid>



            {/* Add Content Section */}
            <Paper style={{width:'90%', padding: '20px', marginBottom: '0px', marginLeft: 20, marginRight:20 ,color :'#4B7857', fontWeight:'bold'}}>
              <Typography variant= "h4"style={{ textAlign: 'center',fontWeight:'bold' }} >{t('Generate Content with Mr. Leaf!')}</Typography>
              <Typography variant= "h5"style={{ textAlign: 'center',fontWeight:'bold' }} >{t('Choose a topic and let our AI tool write your questions')}</Typography>

                <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '-8px' }}>
  {t('*Game Title')} {/* Title text */}
</Typography>
                <TextField label={t('Game Title')} fullWidth margin="normal" value={GameTitle}
                onChange={(e) => setGameTitle(e.target.value)}/>
                <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '-8px' }}>
  {t('CDescription')} {/* Title text */}
</Typography>
                <TextField label={t('CAddDescription')} fullWidth margin="normal"  value={GameDescription}
                onChange={(e) => setDescription(e.target.value)}/>
              <Typography variant= "h4"style={{ textAlign: 'center',fontWeight:'bold' }} >{t('AI related:')}</Typography>

              

<Box
  sx={{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 4, // Adjust gap between the elements
    marginTop: 2,
    width: '100%',
  }}
>
  <Box sx={{ width: '30%' }}>
  <Typography variant="h6" sx={{ fontWeight: 'bold', textAlign: 'center' }}>
  {t('Choose your topic')} {/* Title text */}
</Typography>
    <TextField 
      label={t('Game Topic')} 
      fullWidth 
      value={paragraph}
      onChange={(e) => setParagraph(e.target.value)} 
    />
  </Box>

  {/* <Box sx={{ width: '20%' }}>
    <Typography 
      variant="h6" 
      sx={{ fontWeight: 'bold', textAlign: 'center' }}
    >
      {t('Number of Questions')}
    </Typography>

    <Select
      value={NumberOfQuestions}
      onChange={(e) => setNumberOfQuestions(e.target.value)}
      displayEmpty
      fullWidth
    >
      {[...Array(5)].map((_, index) => (
        <MenuItem key={index + 1} value={index + 1}>
          {index + 1} 
        </MenuItem>
      ))}
    </Select>
  </Box> */}
</Box>
<Box 
  sx={{
    display: 'flex',
    justifyContent: 'center',
    marginTop: 4, // Adjust the spacing from the elements above
  }}
>
<Button
          variant="contained"
          sx={{
            color: 'white',
            marginBottom: '20px',
            borderWidth: '2px',
            backgroundColor: '#3B5D44',
            borderColor: '#3B5D44',
            width: '250px',
            fontWeight: 'bold',
            fontSize: "1rem",
 
          }}
          onClick={() => generateQuestions(paragraph)}        >
          Generate Questions
        </Button>
</Box>
<Box
  sx={{
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    marginLeft: '20px',
    marginTop: 4, // Adjust margin to position correctly
  }}
>
  {questions.length > 0 ? (
    <Box  dir= {language == 'Arabic'? "rtl":"ltr"}>
      
      <List sx={{ width: '100%', maxWidth: 500, padding: 0 }}>
      <Typography variant="h5" sx={{ fontWeight: 'bold', marginBottom: 1 }}>
      Questions:      </Typography>
        {questions.map((q, index) => {
          if (!q || !q.title) {
            return null; // Skip rendering invalid questions
          }

          return (
            
            <Box key={index} sx={{ marginBottom: 2 }}>
              
              <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                Q.{index + 1} {q.title} {/* Question numbering */}
              </Typography>
              <List sx={{ paddingLeft: 0 }}>
                {q.answers.map((answer, answerIndex) => {
                  const letter = String.fromCharCode(65 + answerIndex); // A, B, C, ...
                  return (
                    <ListItem
                      key={answerIndex}
                      sx={{
                        padding: 0, // Remove extra padding
                        marginBottom: 0.5, // Reduce gap between answers
                        color: answer.isCorrect ? 'green' : 'red',
                        fontWeight: answer.isCorrect ? 'bold' : 'normal',
                      }}
                    >
                      {letter}. {answer.text} {/* Answer lettering */}
                    </ListItem>
                  );
                })}
              </List>

            </Box>
          );
        })}
      </List>
      <Box 
  sx={{
    display: 'flex',
    justifyContent: 'center',
    marginTop: 4, // Adjust the spacing from the elements above
  }}
>
<Button
          variant="contained"
          sx={{
            color: 'white',
            marginBottom: '20px',
            borderWidth: '2px',
            backgroundColor: '#3B5D44',
            borderColor: '#3B5D44',
            width: '250px',
            fontWeight: 'bold',
            fontSize: "1rem",
 
          }}
          onClick={() => handleNext(paragraph)}>
          Generate Game
        </Button>
</Box>
    </Box>
  ) : (<></>)}
</Box>

</Paper>
                </Box>


     );
   };
export default ClickPickAI;