import React, { useState } from 'react';
import { Box,Dialog, DialogTitle, DialogContent, Typography, TextField, Button, Link, Grid } from '@mui/material';
import axios from 'axios';
import { useLocation } from 'wouter';
import GoogleIcon from "../../Assets/logos/google.png";
import MicrosoftIcon from "../../Assets/logos/microsoft.png";
import FacebookIcon from "../../Assets/logos/facebook.png";
import AppleIcon from "../../Assets/logos/apple.png";
import pic from "../../Assets/signin.jpeg"
import Loading from "../../pages/reusables/Loading";

function Login({ open, onClose, onSwitch }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [location, setLocation] = useLocation();
  const [isLoading, setIsLoading] = useState(false);


  const handleLogin = async () => {
    setIsLoading(true);
    try {
      // Sending POST request to the backend with login details
      const response = await axios.post("https://qnar.fun/api/login/", {
        username_or_email: email,
        password: password,
      });
  
      // Check if the login is successful
      if (response.data.success) {
        // Save the first name in localStorage (or use state management as needed)
        localStorage.setItem("first_name", response.data.first_name);
        localStorage.setItem('access_token', response.data.access);
        localStorage.setItem("username", response.data.username);
        localStorage.setItem("refresh_token", response.data.refresh);
        // You can also store other relevant user details if needed
        
        // Redirect the user after successful login (e.g., to the home page or dashboard)
        // This assumes you are using Wouter for routing
          // Refresh the page

        setLocation('/dashboard');
        window.location.reload(); // Replace '/dashboard' with your desired route
  
        
      } else {
        setIsLoading(false);
        // Handle login failure (invalid username/email or password)
        alert('Invalid username or password');
      }
    } catch (error) {
      setIsLoading(false);
      // Handle any errors from the request
      console.error('Login failed:', error);
      alert('An error occurred during login. Please try again.');
    }
  };
  return (
<Dialog 
  open={open} 
  onClose={onClose} 
  maxWidth="lg" 
  fullWidth
  sx={{
    '& .MuiDialog-paper': {
      borderRadius: 8,
      maxHeight: '90vh', // Ensure the dialog respects the viewport height
      overflow: 'hidden', // Prevent content from spilling
    },
  }}
>
{isLoading ? (
        <Loading message="Logging you in..."/>
      ) : <></>}
  <Grid 
    container 
    sx={{
      height: { xs: "auto", md: "100%" },
      flexWrap: "wrap",
      alignItems: "center",
    }}
  >
    {/* Form Section */}
    <Grid
      item
      xs={12}
      md={6}
      sx={{
        padding: { xs: 2, md: 4 },
        borderRadius: { xs: 0, md: 8 },
        backgroundColor: "#fff",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        height: { xs: "auto", md: "100%" },
        overflow: "auto", // Allow content to scroll
      }}
    >
      <Box sx={{ maxHeight: "80vh", overflow: "auto", paddingBottom: 2 }}>
        <Typography variant="h4" align="center" sx={{ mb: 3, color: "#5B945B" }}>
          Log In to Qnar
        </Typography>
        <Grid container spacing={2}>
          {/* Email Field */}
          <Grid item xs={12}>
            <TextField
              fullWidth
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Grid>
          {/* Password Field */}
          <Grid item xs={12}>
            <TextField
              fullWidth
              placeholder="Password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </Grid>
        </Grid>
        {/* <Typography sx={{ textAlign: "center", margin: "1rem 0", color: "#5B945B" }}>
          or continue with
        </Typography>
        <Button
          variant="outlined"
          startIcon={<img src={GoogleIcon} alt="Google" style={{ width: "20px" }} />}
          sx={{ width: "100%", mb: 1, textTransform: "none" }}
        >
          Continue with Google
        </Button>
        <Button
          variant="outlined"
          startIcon={<img src={MicrosoftIcon} alt="Microsoft" style={{ width: "20px" }} />}
          sx={{ width: "100%", mb: 1, textTransform: "none" }}
        >
          Continue with Microsoft
        </Button>
        <Button
          variant="outlined"
          startIcon={<img src={FacebookIcon} alt="Facebook" style={{ width: "20px" }} />}
          sx={{ width: "100%", mb: 1, textTransform: "none" }}
        >
          Continue with Facebook
        </Button> */}
        <Button
          variant="contained"
          onClick={handleLogin}
          sx={{
            width: "100%",
            backgroundColor: "#5B945B",
            color: "white",
            mb: 1,
            mt: 2,
            "&:hover": { backgroundColor: "#4A7A4A" },
          }}
        >
          Log in
        </Button>
        <Typography variant="body2" sx={{ textAlign: "center" }}>
          don't have an account?{" "}
          <Link onClick={onSwitch} style={{cursor: 'pointer'}} underline="hover">
            Sign up
          </Link>
        </Typography>
      </Box>
    </Grid>

    {/* Image Section */}
    <Grid
      item
      xs={12}
      md={6}
      sx={{
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: { xs: 2, md: 4 },display: { xs: "none", md: "block" }
      }}
    >
      <img
        src={pic}
        alt="Online Learning"
        style={{
          width: "100%",
          maxHeight: "60vh",
          borderRadius: "8px",
          objectFit: "cover",
          marginBottom: "1rem",
        }}
      />
      <Typography
        variant="h5"
        sx={{
          color: "#5B945B",
          fontWeight: "bold",
          textAlign: "center",
          marginTop: "1rem",
        }}
      >
        من سلك طريقاً يلتمسُ فيه علماً، سهَّل اللهُ له طريقاً إلى الجنَّة
      </Typography>
    </Grid>
  </Grid>
</Dialog>

);
}

export default Login;
