import React from 'react';
import Doha from '../../../Assets/Doha.jpg'
import DohaLong from '../../../Assets/dohaLong.jpg'
import boat from '../../../Assets/boat.png'
import '../../Styles.css'

const DohaBG = ({ isPortrait, animateImages}) => {
  return (
    <div>
      {/* Background Image */}
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)), url(${isPortrait ? DohaLong : Doha})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          zIndex: -10,
        }}
      ></div>

      {/* Boat Image */}
      <img
        src={boat}
        alt="Boat"
        className={`side-image-boat bottom ${animateImages ? 'animateLeft' : ''}`}
        style={{
          zIndex: 97,
          position: 'absolute',
          bottom: isPortrait ? '200px' : '-60px',
          width: isPortrait ? '40vw' : '30vw',
        }}
      />
    </div>
  );
};

export default DohaBG;