import React, {useEffect, useState} from 'react'
import {Box, Typography,  Paper, Button, Grid, Card, CardContent, CardMedia, TextField,  Dialog, DialogContent, DialogActions, IconButton} from '@mui/material';
import { useTranslation } from 'react-i18next';
import UseMediaQuery from '@mui/material/useMediaQuery';
import story from '../../Assets/story.png'
import backBubble from '../../Assets/backBubble.png'
import knar from '../../Assets/Knar.png'
import phone from '../../Assets/phone.png'
import emailPic from '../../Assets/email.png'
import location from '../../Assets/location.png'
import emailjs from 'emailjs-com';
import { Close as CloseIcon } from '@mui/icons-material';
import '../Styles.css'
function AboutUs() {
    const Is1200 = UseMediaQuery('(max-width:1200px)');
    const Is900 = UseMediaQuery('(max-width:900px)');
    const {t, i18n} = useTranslation();

    const [activeSection, setActiveSection] = useState('');
      const phoneNumber = '+97433477044';
  const emailAddress = 'contact@qnarweb.com';
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });
  const handleClickOpen = () => {
    // Perform the actions needed based on the id
    setIsDialogOpen(true);
  };
  

  const handleClose = () => {
    setIsDialogOpen(false);
  };
  const handleChangeEmail = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const sendEmailEmail = () => {


    emailjs.send(
      'service_4xwd306', 
      'template_xofsc8o', 
      formData, 
      'mUqAWzZcVbrWiKca5'
    )
    .then((result) => {
        console.log('Email successfully sent!', result.text);

    }, (error) => {
        console.log('Failed to send email:', error.text);
    });
  setFormData({
    name: '',
    email: '',
    message: '',
  });}
    useEffect(() => {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              setActiveSection(entry.target.id);
            }
          });
        },
        { threshold: 0.5 }
      );
  
      const sections = document.querySelectorAll('section');
      sections.forEach((section) => observer.observe(section));
  
      return () => observer.disconnect();
    }, []);
    const teamMembers = [
      {
        id: 1,
        name: "Samah Qasim",
        role: "CEO",
        image: "https://via.placeholder.com/150",
        bio: "Samah is a visionary leader with a passion for driving innovation and fostering a culture of excellence. she has successfully steered the company to new heights, ensuring that every team member thrives while staying aligned with the company's mission.",
      },
      {
        id: 2,
        name: "Yazan Shehab",
        role: "CTO",
        image: "https://via.placeholder.com/150",
        bio: "Yazan is a tech enthusiast with a knack for strategic thinking and problem-solving. he is the lead developer for the compnay, driving the company's technical roadmap. His expertise in building scalable solutions has been instrumental in achieving operational efficiency and product excellence.",
      },
      {
        id: 3,
        name: "Maha Al Muhannadi",
        role: "Sales Manager",
        image: "https://via.placeholder.com/150",
        bio: "Maha is a results-driven professional with extensive experience in sales and client relations. Her ability to understand customer needs and tailor solutions has significantly increased client satisfaction and revenue growth. Maha thrives on building meaningful relationships and empowering her team.",
      },
    ];
  return (
    <Box sx={{display: 'flex',flexDirection:'column'}}>
       {/* Navigation */}
       <Box
        sx={{
          position: 'sticky',
          top: 60,
          zIndex: 1000,
          backgroundColor: '#fff',
          padding: '10px 0',
          borderBottom: '1px solid #3B5D44',
          color: '#3B5D44'

        }}
      >
        
        <Box sx={{ display: 'flex', justifyContent: 'center', gap: 4 }}>
        <Button
            href="#story"
            sx={{
              textTransform: 'none',
              fontWeight: 'bold',
              color: activeSection === 'story' ? '#EB8576' : 'inherit',
            }}
          >
            Our Story
          </Button>
          <Button
            href="#mission"
            sx={{
              textTransform: 'none',
              fontWeight: 'bold',
              color: activeSection === 'mission' ? '#EB8576' : 'inherit',
            }}
          >
            Mission
          </Button>
          <Button
            href="#what-is-qnar"
            sx={{
              textTransform: 'none',
              fontWeight: 'bold',
              color: activeSection === 'what-is-qnar' ? '#EB8576' : 'inherit',
            }}
          >
            What is Qnar?
          </Button>
         
          <Button
            href="#team"
            sx={{
              textTransform: 'none',
              fontWeight: 'bold',
              color: activeSection === 'team' ? '#EB8576' : 'inherit',
            }}
          >
            Team
          </Button>
          <Button
            href="#contact"
            sx={{
              textTransform: 'none',
              fontWeight: 'bold',
              color: activeSection === 'contact' ? '#EB8576' : 'inherit',
            }}
          >
            Contact Us
          </Button>
        </Box>
      </Box>

      
      
    <Box
      dir="ltr"
        sx={{
            flex:0.8,
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          alignItems: 'center',
          justifyContent: 'center',
          padding: '20px',
          backgroundColor: '#F4DADA',
          textAlign: 'center'
        }}
      >
        <section id="story">
        <Box
        dir="rtl"
          sx={{
            flex: 1,
            padding: '20px',
            textAlign: 'center',
            color: '#3B5D44'
          }}
        >
          <Typography variant="h2" gutterBottom>
          {t('story')}
          </Typography>
          <Typography style={{color:'#C36759'}} variant="h4" gutterBottom>
          {t('storyH')}
          </Typography>
          
          <Typography variant="h6" paragraph>
          {t('storyD')}           </Typography>
          
        </Box><Box
          sx={{
            flex: 1,
            padding: '20px',
            textAlign: 'center',
            color: '#3B5D44'
          }}
        >
          <img
        src={story}
        alt="Background"
        className="story"
        style={{
          zIndex: 1, // Ensure it is behind other content
        }}
      />
        </Box>
        </section>
        </Box>
        
        {/* new section */}
        <Box  sx={{
        
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundImage: `url(${backBubble})`,
          backgroundSize: 'cover', // Cover the entire area
          backgroundPosition: 'center', // Center the image
          backgroundRepeat: 'no-repeat', // Prevent repeating the image
          color: '#3B5D44',
          textAlign: 'center'
          
      }}>
        
          <section id="mission"></section>
        <Typography variant="h2" gutterBottom>
        {t('mission')}
          </Typography>
          <Typography style={{color:'#C36759'}} variant="h4" gutterBottom>
          {t('missionH')}
          </Typography>
          
          <Typography variant="h6" style={{ paddingLeft: '5vw', paddingRight: '5vw'}} paragraph>
          {t('missionD')}
          </Typography>
          <Paper
          id="what-is-qnar"
          elevation={3}
          sx={{
            padding: '20px',      
            maxWidth: Is900? '80%':'60%',   
            display: 'flex',
            flexDirection: { xs: 'column', lg: 'row' }, 
            backgroundColor: '#F9F9ED',
            color: '#3B5D44',
            marginTop: '5vh',
            marginBottom: '5vh'
          }}
        >
          <Typography variant="h2" sx={{display: Is1200 ? 'block' : 'none',textAlign:'center'}} gutterBottom>
          {t('Qnar')}          </Typography>
            <Box
          sx={{
            flex: 0.5,
            padding: '10px',
            textAlign: 'center',
            color: '#3B5D44'
          }}
        >
           <section id="what-is-qnar"></section>
            <img
        src={knar}
        alt="Background"
        className="knar"
        style={{
          // Adjust height as needed
          zIndex: 1, // Ensure it is behind other content
        }}
      />
        </Box>
        <Box
          sx={{
            flex: 1,
            padding: '20px',
            textAlign: Is900?'center' : (i18n.language === 'ar'? 'right': 'left'),
            color: '#3B5D44'
          }}
        >
          <Typography variant="h2" sx={{display: Is1200 ? 'none' : 'block',}} gutterBottom>
          {t('Qnar')}      </Typography>
          <Typography variant="h6">
          <span style={{ color: '#EB8576' }}>{t('Q')}</span>{t('QnarE')}  <span style={{ color: '#EB8576' }}>{t('Q2')}</span>
          {t('QnarE2')} <span style={{ color: '#EB8576' }}>{t('Q3')}</span>
          {t('QnarE3')}          </Typography>
        
               </Box>
        </Paper>

        </Box>

        <section className="our-team" id = "team">
      <Typography variant="h2" component="h2" align="center" sx={{color: '#3B5D44'}} gutterBottom>
        Our Team
      </Typography>
      <Typography variant="body1" align="center" color="textSecondary" paragraph>
        Meet the people who make it all happen.
      </Typography>
      <Grid container spacing={4}>
        {teamMembers.map((member) => (
          <Grid item key={member.id} xs={12} sm={6} md={4}>
            <Card sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
              <CardMedia
                component="img"
                image={member.image}
                alt={member.name}
                sx={{ height: 200, objectFit: "cover" }}
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="h3">
                  {member.name}
                </Typography>
                <Typography variant="subtitle1" color="textSecondary">
                  {member.role}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {member.bio}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </section>
    <section id= "contact">
    <Box sx={{overflow: 'hidden',display: 'flex',flexDirection:'column'}}>
    <Box sx={{flex: 1,display: 'flex',alignItems: 'center', justifyContent: 'center',}}><Typography style={{color:'#3B5D44'}} variant='h2'>{t('contact')}</Typography></Box>
      <Box sx={{flex: 1,display: 'flex',  flexDirection: 'row',alignItems: 'center', justifyContent: 'center'}}>
      <a style={{marginRight:'5vw'}} href={`tel:${phoneNumber}`}><img className="contactIMG" src={phone}alt="Background"/></a>
      <a style={{marginRight:'5vw'}} href={`mailto:${emailAddress}`}>< img  className="contactIMG" src={emailPic}alt="Background"/></a>
      <a style={{marginRight:'5vw'}}><img className="contactIMG" src={location}alt="Background"/></a></Box>
    <Box
      sx={{
        display: 'flex',
        flexDirection: {xs:'column', md:'row'}, // Column on small screens, row on larger
        justifyContent: 'space-between',
        padding: '2rem',
        gap: '2rem',
        
      }}
    >
      {/* Left Box: Contact Form */}
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          padding: '2rem',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
          borderRadius: '10px',
          backgroundColor: '#f9f9f9',
        }}
      >
        <Typography variant="h4" gutterBottom style={{color:'#3B5D44'}}>{t('getInTouch')} 
     
        </Typography>

        <form onSubmit={sendEmailEmail}>
        <TextField
           label={t('name')} 
          variant="outlined"
          fullWidth
          margin="normal"
          name="name" // Add this to associate the input with formData.name
        //   InputProps={{
        //     startAdornment: formData.name === '' ? (
        //       <InputAdornment position={i18n.language === "ar" ? "end" : "start"}>
        //         {t('name')}
        //       </InputAdornment>
        //     ) : null,
        // }}

        value={formData.name}
        onChange={handleChangeEmail}
        required
        />
        <TextField
          label={t('Email')} 
          variant="outlined"
          fullWidth
          margin="normal"
     
          name="email" // Add this to associate the input with formData.email
          value={formData.email}
          onChange={handleChangeEmail}
          required
       />
        <TextField
          label={t('message')} 
          variant="outlined"
          fullWidth
          dir='auto'
          multiline
          rows={4}
          margin="normal"
          name="message" // Add this to associate the input with formData.message
          value={formData.message}
          onChange={handleChangeEmail}
       />

        <Button
          variant="contained"
          sx={{ backgroundColor: '#4B7857', color: '#fff', marginTop: '1rem' }}
           type="submit"
           onClick={() => {
            if (formData.name !== "" && formData.email !== "") {
              handleClickOpen();
            }}}
        >
          {t('Submit')}  
        </Button>

    </form>
      </Box>

      {/* Right Box: Map */}
      <Box
        sx={{
          flex: 1,
          height: '100%',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
          borderRadius: '10px',
          overflow: 'hidden',
         
          height: '80vh',
        }}
      >
<iframe
    title="Map"
    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1516.29725708653!2d51.43723384478978!3d25.32492686717924!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e45dc1b48888243%3A0xc99991c5589f3b25!2sQatar%20Science%20and%20Technology%20Park!5e0!3m2!1sen!2sjo!4v1726347410248!5m2!1sen!2sjo"
    width="100%"
    height="100%"
    style={{ border: 0 }} // Properly passing the style object here
    allowFullScreen=""
    loading="lazy"
  ></iframe>  </Box></Box>
   {/* Dialog */}
   <Dialog
        open={isDialogOpen}
        onClose={handleClose}
        PaperProps={{
          sx: {
            padding: '20px',
            borderRadius: '20px',
            width: '400px',
            maxWidth: '90%',
          }
        }}
      >
        {/* Close button on the top-right corner */}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: '#aaa',
          }}
        >
          <CloseIcon />
        </IconButton>

        {/* Dialog content */}
        <DialogContent sx={{ textAlign: 'center' }}>


      <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', color: '#333' }}>
        {t('Thanks')}
      </Typography>


        <Typography variant="body1" sx={{ color: '#666' }}>
          {t('msg')}
        </Typography>
      

  
</DialogContent>

        {/* Dialog actions with custom button */}
        <DialogActions sx={{ justifyContent: 'center', paddingBottom: '20px' }}>
          <Button
            variant="contained"
            onClick={handleClose}
            sx={{
              backgroundColor: '#4caf50',
              color: '#fff',
              borderRadius: '50px',
              padding: '10px 30px',
              '&:hover': {
                backgroundColor: '#45a049',
              },
            }}
          >
            {t('close')}
          </Button>
        </DialogActions>
      </Dialog>
  
  </Box>
  </section>

      </Box>
  )
}

export default AboutUs