import '../Styles.css'
import axios from 'axios';
import CelebrationScreen from "../reusables/CelebrationScreen";
import TimeUpPopup from '../reusables/TimeUpPopup';
import DynamicBG from '../reusables/backgrounds/DynamicBackground';
import GameIntroPage from '../reusables/GameIntroPage';
import React, { useState, useEffect, useRef } from 'react';
import { Box, Button, Grid, Typography, Card, CardActionArea } from '@mui/material';
import { isMobile } from 'react-device-detect';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import WarningIcon from '@mui/icons-material/Warning';

import soundIcon from '../../Assets/sound.png'
import menu from '../../Assets/menu.png'
import timer from '../../Assets/timer.png'
import max from '../../Assets/maximize.png'
import min from '../../Assets/minimize.png'

import settingsIcon from '../../Assets/settings.png'
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import ScoreName from '../reusables/ScoreName';
import Header from '../StaticPages/Header';
import Footer from '../StaticPages/Footer';
//import { useMediaQuery } from '@mui/material';
import { useRoute, useLocation } from 'wouter';
import { CenterFocusStrong } from '@mui/icons-material';


function ClickPick() {
  const [location, setLocation] = useLocation();
  const [language, setLanguage] = useState("");
  
  const [shuffledAnswers, setShuffledAnswers] = useState([]);
  const [matchPlay, paramsPlay] = useRoute('/play/ClickPick/:id');
  const [matchEmbed, paramsEmbed] = useRoute('/embed/ClickPick/:id');
  
  // Determine the matched route and corresponding ID
  const params = matchPlay ? paramsPlay : matchEmbed ? paramsEmbed : {};
  const { id } = params; // Get the `id` from the matched route
  const [questions, setQuestions] = useState([{
    question: "question sample", answers: [{ text: "Mercury", isCorrect: true },
    { text: "Earth", isCorrect: true },]
  }]);
  const [settings, setSettings] = useState([{}]);
  const [isOneAnswer, setIsOneAnswer] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const handleOpenPopup = () => {
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedAnswers, setSelectedAnswers] = useState([]);
  const [score, setScore] = useState(0);
  const [scores, setScores] = useState([]);
  const [submitted, setSubmitted] = useState(false);

  const [sound, setSound] = useState(true);
  const [isFullscreen, setIsFullscreen] = useState(false);

  const [time, setTime] = useState(25); // Initial time
  const [InitialTime, setITime] = useState(25);
  const [isTimerActive, setTimerActive] = useState(false); // Timer is active
  const [animationKey, setAnimationKey] = useState(0);
  const currentQuestion = questions[currentQuestionIndex];
  const [isIphone, setIPhone] = useState(false)
  const [count, setCount] = useState(3);
  const [start, setstart] = useState(false);
  const [finish, setFinish] = useState(false);
  const [showGame, setShowGame] = useState(false);
  const [videoVisible, setVideoVisible] = useState(false);
  const [animateImages, setAnimateImages] = useState(false); // Control the animation of images
  const [timeUp, setTimeUp] = useState(false);
  const [fullIphone, setFullIphone] =useState(false);
  const videoRef = useRef(null);

  const containerRef = useRef(null);

  const [isPortrait, setIsPortrait] = useState(window.matchMedia('(orientation: portrait)').matches);
  const [isConditionMet, setIsConditionMet] = useState(false);

  const [countdown, setCountdown] = useState(5);
  const [showIndicator, setShowIndicator] = useState(false);
  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);
  const [isEmbedded, setIsEmbedded] = useState(false);
  const [embedCode, setEmbedCode] = useState("");
  const [isPopName, setIsPopName] = useState(true);


  useEffect(() => {
    const handleResize = () => {
      setViewportHeight(window.innerHeight);
    };

    handleResize(); // Set on initial load
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
    let timer;
    if (showIndicator) {
      timer = setInterval(() => {
        setCountdown((prev) => {
          if (prev === 1) {
            clearInterval(timer);

            setShowIndicator(false); // Hide the indicator after the countdown
            handleSubmit(); // Trigger the next question
          }
          return prev - 1;
        });
      }, 1000);
    }

    return () => clearInterval(timer);
  }, [showIndicator]);

  useEffect(() => {
    // Shuffle answers when the question changes
    if (/[\u0600-\u06FF]/.test(currentQuestion.title)) {
      setLanguage("Arabic");
    } else if (/^[a-zA-Z]+$/.test(currentQuestion.title)) {
      setLanguage("English");
  }
    
    if (currentQuestion?.answers) {
      setShuffledAnswers(shuffleArray([...currentQuestion.answers]));
    }
  }, [currentQuestion]);
  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]]; // Swap elements
    }
    return array;
  };
  const areAllQuestionsValid = () => {
    return questions.every((question) => {
      // Count how many answers have isCorrect: true
      const correctAnswers = question.answers.filter((answer) => answer.isCorrect).length;
      // Ensure only 1 correct answer exists
      return correctAnswers === 1;
    });
  };

  useEffect(() => {
    fetchScores();
    const username = localStorage.getItem('username');
    const guestName = localStorage.getItem('guest_name');
    setIsOneAnswer(areAllQuestionsValid());
    if (username || guestName){
      setIsPopName(false)
    }
  }, [questions]);

  useEffect(() => {
    GetQuestions();
    GetGame();

    const handleResize = () => {
      const height = window.innerHeight;
      const width = window.innerWidth;


      if (height - width <= 300 && height > 700) {
        setIsConditionMet(true);
      } else {
        setIsConditionMet(false);
      }
    };

    window.addEventListener('resize', handleResize);

    // Initial check
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
    
  }, []);
  ////////////////////////// start /////////////////////////////
  useEffect(() => {
    const mediaQuery = window.matchMedia('(orientation: portrait)');

    const handleOrientationChange = (e) => {
      if (isMobile) {

        setIsPortrait(e.matches);
        window.scrollTo({
          top: 56,
          behavior: 'smooth', // Adds a smooth scrolling effect
        });
      }
      else {
        setIsPortrait(window.matchMedia('(orientation: portrait)').matches);
      }
      
      // Update state based on orientation
    };

    mediaQuery.addEventListener('change', handleOrientationChange);

    // Cleanup listener on component unmount
    return () => {
      mediaQuery.removeEventListener('change', handleOrientationChange);
    };
  }, []);
  const getDeviceType = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Detect iOS
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "iOS";
    }

    // Detect Samsung (and other Android devices)
    if (/android/i.test(userAgent)) {
      if (/Samsung/i.test(userAgent)) {
        return "Samsung";
      }
      return "Android";
    }

    return "Other";
  };
  useEffect(() => {
    window.scrollTo({
      top: 56,
      behavior: 'smooth', // Adds a smooth scrolling effect
    });
    const deviceType = getDeviceType();

    if (deviceType === "iOS") {

      setIPhone(true);
      // } else if (deviceType === "Samsung" || deviceType === "Android") {
      //   setMobile(true);
    }

    const onFullScreenChange = () => {
      if (!document.fullscreenElement) {
        setIsFullscreen(false); // Call handleFullScreen when exiting fullscreen
      }
    };

    document.addEventListener('fullscreenchange', onFullScreenChange);

    return () => {
      document.removeEventListener('fullscreenchange', onFullScreenChange);
    };

  }, []);


  const fetchScores = async () => {
    try {
      const response = await axios.get(`https://qnar.fun/api/scores/game/${id}/`);
      setScores(response.data);
    } catch (error) {
      console.error("Error fetching scores:", error);
    }
  };
  const handleStart = () => {
    // Play video when the button is clicked
    setstart(true);
    setCount(3)
    setFinish(false);
    setScore(0);


    if (videoRef.current) {

      videoRef.current.currentTime = 0;
      videoRef.current.play();
      setTimeout(() => {


        //playSound(1);
      }, 1000);
    }



    //playSound(0);
    setVideoVisible(true);

    setTimeout(() => {

      setAnimateImages(true); // Start animating side images
      setVideoVisible(false);

    }, 3000);

    // Show the wheel after 4 seconds
    setTimeout(() => {
      setShowGame(true);
      setTime(25);
      setAnimationKey((prev) => prev + 1);
      setTimerActive(true);
    }, 5000);
    setAnimateImages(false);
    // Start the timer
  };


  const submitScore = async () => {
    const userToken = localStorage.getItem('access_token');
    const username = localStorage.getItem('username');
    const guestName = localStorage.getItem('guest_name'); // Guest user's name
    if (!userToken && !guestName) {
      console.log("No token found!");
      return;
    }
  
    try {
      const headers = {
        'Content-Type': 'application/json',
    };

    // Include Authorization only if token exists
    if (userToken) {
        headers['Authorization'] = `Bearer ${userToken}`;
    }
    let body = "";
    // Include username or guest_name depending on the user type
    if (username) {
       body = {
        game: id,
        score: score,
        username: username, 
    }
        
    } else if (guestName) {
      body = {
        game: id,
        score: score,
        guest_name: `guest: ${guestName}`
    }
        
    }
      const response = await fetch('https://qnar.fun/api/scores/', {
        method: 'POST',
        headers: headers,
        
        body: JSON.stringify(body
  ),
      });
  
      if (response.ok) {
        const data = await response.json();
        console.log('Score submitted successfully:', data);
        fetchScores();
        return data;
      } else {
        const errorData = await response.text();
        console.error('Failed to submit score:', errorData);
      }
    } catch (error) {
      console.error('Error posting score:', error);
    }
  };
  ////////////////////////// timer /////////////////////////////
  useEffect(() => {
    let interval = null;

    if (isTimerActive && time > 0) {
      interval = setInterval(() => {
        setTime(prevTime => prevTime - 1);
      }, 1000); // Update every second
    } else {
      clearInterval(interval);
      if (time === 0) {
        setTimeUp(true); // Call the callback function when time is up
        setCountdown(5);
        setShowIndicator(true);
      }
    }

    return () => clearInterval(interval); // Cleanup interval on unmount
  }, [isTimerActive, time]);
  ////////////////////////////end of timer ///////////////////////


  const handleAnswerClick = (index) => {
    if (!submitted) {
      setSelectedAnswers((prev) =>
        prev.includes(index) ? prev.filter((i) => i !== index) : [...prev, index]
      );
    }
  };
  useEffect(() => {
    if (isOneAnswer && selectedAnswers.length > 0) {
      handleSubmit();
    }
  }, [selectedAnswers, isOneAnswer]);

  const handleSubmit = () => {
    if (submitted || timeUp) {
      setTimeUp(false);
      setTime(25);
      setAnimationKey((prev) => prev + 1);
      setTimerActive(true);
      setSelectedAnswers([]);
      setSubmitted(false);
      setShowIndicator(false);
      if (currentQuestionIndex < questions.length - 1) {
        setCurrentQuestionIndex((prev) => prev + 1);
      } else {
        setTimeUp(false);
        setTimerActive(false);
        setShowGame(false);
        setFinish(true);
        setCurrentQuestionIndex(0);
        submitScore();
        //setScore(0);
      }
    } else {
      // Check answers and update score
      setCountdown(5);
      setShowIndicator(true); // Start the countdown

      setTimerActive(false);
      const correctAnswers = shuffledAnswers
        .map((answer, index) => (answer.isCorrect ? index : null))
        .filter((index) => index !== null);
        console.log("Total correct answers: ", selectedAnswers);

        const penalty = 2; // Penalty per incorrect answer
        
        // Ensure `correctAnswers` is always an array, even if there's one correct answer
        const pointsEarned = selectedAnswers.reduce((acc, index) => {

          if (correctAnswers.includes(index)) { // Check if the answer is correct
            return acc + 10;  // Add points for a correct answer
          } else {
            return acc - penalty; // Subtract points for an incorrect answer
          }
        }, 0);
        // Ensure points don't go below zero
        const adjustedPoints = Math.max(0, pointsEarned);
        // Time multiplier
        const timeMultiplier = 1 + time / InitialTime; // Adjust multiplier as needed
        let pointsEarneda = Math.round((adjustedPoints * timeMultiplier) );
        // Update the score
        setScore((prev) => prev + pointsEarneda);
        
      setSubmitted(true);
    };
  }

  const handleSound = () => { if (sound) { setSound(false); } else { setSound(true); } };


  const handleFullScreen = () => {
    if (isIphone){
      setFullIphone(true);
    }
    if (!isFullscreen) {
      // Enter fullscreen
      if (containerRef.current.requestFullscreen) {
        containerRef.current.requestFullscreen();
      } else if (containerRef.current.mozRequestFullScreen) {
        containerRef.current.mozRequestFullScreen();
      } else if (containerRef.current.webkitRequestFullscreen) {
        containerRef.current.webkitRequestFullscreen();
      }
      else if (containerRef.current.webkitEnterFullscreen) {
        containerRef.current.webkitEnterFullscreen();
      }
      else if (containerRef.current.msRequestFullscreen) {
        containerRef.current.msRequestFullscreen();
      }
      setIsFullscreen(true);
      if (isIphone){

        window.scrollTo(0, 1);
      setFullIphone(true);
      document.body.style.overflow = "hidden";
    }
    } else {
      //Exit fullscreen
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
      setIsFullscreen(false);

      if (isIphone){
      setFullIphone(false);
      document.body.style.overflow = "auto";
    }
    }
  };

  ////////////////////////////////  api //////////////////////////////////
  const GetQuestions = async () => {
    try {
      const response = await fetch(`https://qnar.fun/api/questions/?game=${id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const data = await response.json();

      setQuestions(data)
      return data;
    } catch (error) {
      console.error('Error fetching questions:', error);
    }
  };
  const GetGame = async () => {
    try {
      const response = await fetch(`https://qnar.fun/api/games/${id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const data = await response.json();
      setSettings(data)
      return data; // Return the game ID for creating questions
    } catch (error) {
      console.error('Error posting game:', error);
    }
  };




   useEffect(() => {
  
      const currentURL = window.location.href;
      // Check if it contains the word "play"
      if (currentURL.includes("play")) {
        setIsEmbedded(false);      // Do something here
      }
      else{
        setIsEmbedded(true)
      }
      setEmbedCode(
        `<iframe src="https://qnarweb.com/embed/clickpick/${id}
" width="100%" height="100%" style="border: none;" title="Embedded Content"></iframe>`);

    }, [location]);
  

  const handleCopyEmbedCode = () => {
    navigator.clipboard.writeText(embedCode)
      .then(() => {
        alert("Embed code copied to clipboard!");
      })
      .catch((err) => {
        alert("Failed to copy: ", err);
      });
  };


  const handlePopupName = () => {
    if (isPopName){
      setIsPopName(false);}
    else{
      setIsPopName(true);
    }
  };

  const handleNameSubmit = (name) => {
    if (scores.some((score) => score.user_username === name || score.guest_name === name)){
      alert("Name already Exists");
      }
    else{
    localStorage.setItem("guest_name", name);
    setIsPopName(false);}
    // Check if the name exists in the top 10 and handle accordingly
  };
  return (
 
   <>
      <Box ref={containerRef} className={fullIphone? 'FSMB': isFullscreen ? 'FSbg' : 'normalDT'}
        sx={{aspectRatio: '5 / 3', flexDirection: 'column', borderRadius: 2,  display: 'flex',
        
         alignItems: 'Center', boxShadow: 3, textAlign: 'center', overflow: 'hidden', 
         zIndex: fullIphone? 1000000: 1000, paddingLeft: '20px', paddingRight: '20px',
         
         height: fullIphone? `${viewportHeight}px`: isFullscreen? '100vw' : isEmbedded? '90vh': '70vh',
         paddingTop: 'env(safe-area-inset-top)', // iPhone safe area
         paddingBottom: 'env(safe-area-inset-bottom)', // iPhone safe area
         '@media (max-height: 530px)': {
          aspectRatio: '7 / 3', // Fullscreen: Height is 2x the width
    },
         }}>
        {/* Background Image */}
        <DynamicBG isPortrait={isPortrait} animateImages={animateImages} number={1} />
        {isPopName && (
        <ScoreName
          scores = {scores}
          onSubmit={handleNameSubmit}
          
        />
      )}
        {/*///////////////////////////////////////////////////////////////////////////////////////////////////////////  */}
        <GameIntroPage
          isPortrait={isPortrait}
          start={start}
          showGame={showGame}
          settings={settings}
          isFullscreen={isFullscreen}
          handleStart={handleStart}
          handleFullScreen={handleFullScreen}
          VideoVisible={videoVisible}
          isIphone={isIphone}
          videoRef={videoRef}
        />
        {/*///////////////////////////////////////////////////////////////////////////////////////////////////////////  */}


        <Box className={`game-container ${showGame ?  'show FSMT' : ''}`} 
        sx={{ display: 'flex', flex: '0 0 5%', justifyContent: 'space-between', mb: isIphone && !isPortrait? 0.3 : 2, width: '100%' }}>
          <Box sx={{ display: 'flex', gap: 1,   }}>
            <Box sx={{
              position: 'relative',
              width: { xs: '6vw', sm: '5vw', md: '4vw', lg: '3vw' }, // Set desired size
              height: { xs: '6vw', sm: '5vw', md: '4vw', lg: '3vw' }, // Square aspect ratio
              '@media (max-height: 500px)': {
                width: { xs: '5vw', sm: '4vw', md: '3vw', lg: '2vw' }, // Set desired size
                height: { xs: '5vw', sm: '4vw', md: '3vw', lg: '2vw' }, // Square aspect ratio
    },
              overflow: 'hidden',
              marginRight: '5px'
            }} >
              <img src={soundIcon} alt="button" onClick={handleSound} style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                objectFit: 'cover',
              }} />
            </Box>
            <Box sx={{
              position: 'relative',
              width: { xs: '6vw', sm: '5vw', md: '4vw', lg: '3vw' }, // Set desired size
              height: { xs: '6vw', sm: '5vw', md: '4vw', lg: '3vw' }, // Square aspect ratio
              '@media (max-height: 500px)': {
                width: { xs: '5vw', sm: '4vw', md: '3vw', lg: '2vw' }, // Set desired size
                height: { xs: '5vw', sm: '4vw', md: '3vw', lg: '2vw' }, // Square aspect ratio
    },
              overflow: 'hidden',
               marginRight: '5px'
            }} >
              <img src={menu} alt="button" onClick={handleSound} style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                objectFit: 'cover',
              }} />
            </Box>
            <Box sx={{
              position: 'relative',
              width: { xs: '6vw', sm: '5vw', md: '4vw', lg: '3vw' }, // Set desired size
              height: { xs: '6vw', sm: '5vw', md: '4vw', lg: '3vw' }, // Square aspect ratio
              '@media (max-height: 500px)': {
                width: { xs: '5vw', sm: '4vw', md: '3vw', lg: '2vw' }, // Set desired size
                height: { xs: '5vw', sm: '4vw', md: '3vw', lg: '2vw' }, // Square aspect ratio
    },
              overflow: 'hidden', // Prevent the image from spilling out
               marginRight: '5px',
              
            }}>
              <img
                src={timer}
                alt="button"
                onClick={handleSound}
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  objectFit: 'contain', // This ensures the whole image is visible within the container
                }}
              />
              <Typography
                variant="h6"
                sx={{
                  position: 'absolute',
                  color: '#3B5D44',
                  fontWeight: 'bold',
                  fontSize: { xs: '0.7rem', sm: '1.5rem', md: '1.1rem', lg: '1.5rem' }, // Font size adjusts across breakpoints
                
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)', // Center the text
                  '@media (max-height: 500px)': {
                    fontSize: '0.7rem'
                  },
                }}
              >
                {time}
              </Typography>
            </Box>
          </Box>

          <Box className={"green-to-white-wrapper"} sx={{ marginright: '20px', display: { xs: 'none', md: "block" },marginRight: '5px', 
              height: { xs: '6vw', sm: '5vw', md: '4vw', lg: '3vw' }, // Square aspect ratio
              '@media (max-height: 500px)': {
          height: { xs: '5vw', sm: '4vw', md: '3vw', lg: '2vw' }, // Square aspect ratio
    }, }} key={animationKey}>
            <div className={`green-to-white-overlay ${isTimerActive ? '' : 'paused'}`} style={{ display: isPortrait ? 'none' : "block",  }}></div>
          </Box>

          <Box sx={{ display: 'flex', gap: 1 }}>
            <Box sx={{
              background: `linear-gradient(rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9))`, color: 'FFFFFF', borderRadius: '10px',
              borderColor: '#4B7857', borderWidth: '3px', borderStyle: 'solid', position: 'relative',
              width: { xs: '24vw', sm: '18vw', md: '14vw', lg: '10vw' }, // Set desired size
              height: { xs: '6vw', sm: '5vw', md: '4vw', lg: '3vw' }, // Square aspect ratio
              
              '@media (max-height: 500px)': {
                padding: '0px',
                height: { xs: '5vw', sm: '4vw', md: '3vw', lg: '2vw' }, // Square aspect ratio
    },
              overflow: 'hidden', // Prevent the image from spilling out

              padding: isFullscreen ? { xs: '1px', sm: '3px',  lg: '10px' }: { xs: '1px', sm: '3px', md: '5px' },
              fontSize: { xs: '1rem', sm: '1.5rem', md: '2rem' },
              color: '#3B5D44',
      
            }}>
              <Typography variant="h6" sx={{ fontWeight: 'bold', 
              fontSize: '1rem',
    //           '@media (max-height: 700px)': {
    //             fontSize: '1rem' // Square aspect ratio
    // },
    }}>Score: <span style={{ color: '#EB8576' }}>{score}</span></Typography>
            </Box>
            <Box sx={{
              position: 'relative',
              width: { xs: '6vw', sm: '5vw', md: '4vw', lg: '3vw' }, // Set desired size
              height: { xs: '6vw', sm: '5vw', md: '4vw', lg: '3vw' }, // Square aspect ratio
              '@media (max-height: 500px)': {
                width: { xs: '5vw', sm: '4vw', md: '3vw', lg: '2vw' }, // Set desired size
                height: { xs: '5vw', sm: '4vw', md: '3vw', lg: '2vw' }, // Square aspect r
    },
              overflow: 'hidden', // Prevent the image from spilling out
            }}>
              <img src={isFullscreen ? min : max} alt="button" onClick={handleFullScreen} style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                objectFit: 'contain', // This ensures the whole image is visible within the container
              }} />
            </Box>
          </Box>
        </Box>
        

        {/* /////////////////////////////////// end of navbar/////////////////////////////////////////////////////// */}

        
        <Box
  className={`game-container ${showGame ? isIphone && !isPortrait? 'show' :isMobile ? 'show' : 'show': ''}`}
  dir= {language == 'Arabic'? "rtl":"ltr"}
  sx={{
    display: 'flex',
    flex:  '0 0 10%' ,
    
    justifyContent: 'center',
    alignItems: 'center', // Center vertically for better alignment
    mb: isIphone && !isPortrait? 0: isPortrait && isFullscreen? 2 : isFullscreen? 5 : 2,

    background: `linear-gradient(rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9))`,
    color: '#FFFFFF', // Corrected missing #
    borderRadius: '10px',
    borderColor: '#4B7857',
    borderWidth: '3px',
    borderStyle: 'solid',
    //padding: { xs: '5px', sm: '10px' },

    width: '100%',
    fontSize:isPortrait && isFullscreen? '1rem': isFullscreen? '2rem':'clamp(1rem, 1.5vw, 2rem)', // Responsive font size
    fontWeight: 'bold',
    color: '#3B5D44', // Font color inside the Box
    textAlign: 'center', // Center-align text
    lineHeight: 1.2, // Adjust line height for better readability
    boxSizing: 'border-box', // Ensure padding doesn't overflow height
    '@media (max-height: 450px)': {
        mb : 1, // Fullscreen: Height is 2x the width
    },
  }}
>
  {currentQuestion.title}
</Box>



{/* ////////////////////////////////////////////////////// */}
<Box
  className={`game-container ${showGame ? isMobile ? 'show FSMTM' : 'show' : ''}`}
  sx={{
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 2,
    padding: 1,
    width: '100%',
  }}
>
  {shuffledAnswers.length > 0 && (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        gap: 2,
        width: isMobile && isPortrait ? '100%' : isFullscreen? '50%' : '80%',
        '@media (max-height: 450px)': {
          gap: 1,
      },
        
            }}
    >
      {shuffledAnswers.map((answer, index) => {
        let icon = null;
        let backgroundColor = '#E6F8EB'; // Default color

        if (submitted) {
          if (answer.isCorrect && !selectedAnswers.includes(index)) {
            backgroundColor = 'lightgreen';
            icon = <WarningIcon sx={{ color: 'white', marginBottom: '-20px' }} />;
          } else if (answer.isCorrect) {
            backgroundColor = 'green';
            icon = <CheckIcon sx={{ color: 'white', marginBottom: '-20px' }} />;
          } else if (selectedAnswers.includes(index)) {
            backgroundColor = 'red';
            icon = <CloseIcon sx={{ color: 'white', marginBottom: '-20px' }} />;
          }
        } else if (selectedAnswers.includes(index)) {
          backgroundColor = '#FFFFCD';
        }
        
        return (
          <Card
          onClick={() => { handleAnswerClick(index);  }}
          key={index}
            sx={{
              flex: isPortrait ? '1 1 calc(50% - 16px)': shuffledAnswers.length === 4 || 3 ? '1 1 calc(50% - 16px)' : '1 1 calc(33.33% - 16px)',
              maxWidth: '200px',
              height: isPortrait && isFullscreen?  'calc(0.6 * 100%)' :
               isPortrait? 'calc(0.4 * 100%)': isFullscreen? 
               'calc(2 * 100%)': 'calc(0.7 * 100%)' ,
              maxHeight: '200px',
              color: 'black',
              textAlign: 'center',
              padding: 1,
              boxShadow: 3,
              borderRadius: '8px',
              cursor: 'pointer',
              backgroundColor,
              overflowWrap: 'break-word', // Modern equivalent of wordWrap
              display: 'flex', // Enable flexbox
              flexDirection: 'column', // Ensure vertical alignment
              justifyContent: 'center', // Center vertically
              alignItems: 'center', // Center horizontally
              lineHeight: '0.5',
      
          whiteSpace: 'normal',
              '@media (max-height: 600px)': {
                  height: isPortrait && isFullscreen?'calc(0.4 * 100%)':isFullscreen?'calc(0.7 * 100%)': 'calc(0.5 * 100%)',
                   // Fullscreen: Height is 2x the width
              },
              // '@media (max-height: 450px)': {
              //     height: 'calc(0.6 * 100%)', // Fullscreen: Height is 2x the width
              //     padding: 0,
              // },
              '&:hover': 
              isMobile || selectedAnswers.includes(index)
                ? { bgcolor: backgroundColor,
                   transform: 'scale(1.05)',
                }
                : {
                    bgcolor: 'lightblue',
                    transform: 'scale(1.05)',
                    
              },
            }}
          >

              {icon}
              <Typography variant="h6" sx={{ fontWeight: "bold", overflowWrap: 'break-word', // Prevent long words from overflowing
      whiteSpace: 'normal', fontSize:isPortrait && isFullscreen?'1rem': isFullscreen? 
                '2rem': {sx:'0.6rem',sm:'0.9rem', md: '1.3rem', lg: '1.3rem'} ,
                '@media (max-height: 600px)': 
                {fontSize: isPortrait && isFullscreen? '1rem': '1.3rem'},
                  '@media (max-height: 350px)': {
                  height: isFullscreen?'0.9rem': '', // Fullscreen: Height is 2x the width
              },
    }}>{answer.text}</Typography>

          </Card>
        );
      })}
    </Box>
  )}
</Box>
  
<Grid
  container
  flexDirection="column" // Stack items vertically
  justifyContent="flex-end" // Align items to the bottom of the container
  alignItems="flex-end" // Align items to the right of the container
  style={{
    position: "absolute", // Ensure the container is positioned relative to the screen
    bottom: "20px", // Distance from the bottom of the screen
    right: "20px", // Distance from the right of the screen
    width: "auto", // Allow the container to size dynamically
  }}
>
  {/* Button */}
  <Grid item>
    <Button
      className={`game-container ${showGame
        ? isMobile
          ? "show "
          : isFullscreen
            ? "show "
            : "show"
        : ""
        }`}
      variant="contained"
      onClick={handleSubmit}
      sx={{
        fontWeight: "bold",
        width: "fit-content",
        backgroundColor: "#EB8576",
        marginBottom: "10px", // Add spacing between button and countdown
        display: isOneAnswer && !submitted ? "none" : "block", // Conditional visibility
      }}
    >
      {submitted
        ? currentQuestionIndex < questions.length - 1
          ? "Next Question"
          : "Finish"
        : "Submit"}
    </Button>
  </Grid>

  {/* Countdown */}
  {showIndicator && !timeUp && (
    <Grid item>
      <span
        style={{
          color: "red",
          fontWeight: "bold",
          fontSize: "2rem",
        }}
      >
        {countdown}
      </span>
    </Grid>
  )}
</Grid>
        {finish && (
          <CelebrationScreen
            score={score}
            onReplay={handleStart}
            isFullscreen={isFullscreen}
            handleFullScreen={handleFullScreen}

          />
        )}
        <TimeUpPopup
        timeUp={timeUp}
        countdown={countdown}
        handleSubmit={handleSubmit}
        currentQuestionIndex={currentQuestionIndex}
        questions={questions}
      />


      </Box>
      <div style={{ textAlign: 'center'  }}>
      <h2>Top 10 Scores</h2>
      {scores.length > 0 ? (
        <table style={{ margin: '0 auto', borderCollapse: 'collapse', width: '80%' }}>
          <thead>
            <tr>
              <th style={{ border: '1px solid black', padding: '8px' }}>Rank</th>
              <th style={{ border: '1px solid black', padding: '8px' }}>Player</th>
              <th style={{ border: '1px solid black', padding: '8px' }}>Score</th>
            </tr>
          </thead>
          <tbody>
            {scores.map((score, index) => (
              <tr key={index}>
                <td style={{ border: '1px solid black', padding: '8px' }}>{index + 1}</td>
                {score.user_username == null? 
                <td style={{ border: '1px solid black', padding: '8px' }}>{score.guest_name}</td> :

                <td style={{ border: '1px solid black', padding: '8px' }}>{score.user_username}</td>}
                <td style={{ border: '1px solid black', padding: '8px' }}>{score.score}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>No scores yet.</p>
      )}
    </div>
      {isEmbedded? <></>:
      <>
      <button onClick={handleOpenPopup}>Get Embedded Code</button>
      {showPopup && (
        <div style={overlayStyle}>
          <div style={popupStyle}>
            <button onClick={handleClosePopup} style={closeButtonStyle}>X</button>
            <div style={contentStyle}>
              {/* Preview iframe */}
              <div style={previewStyle}>
                {/* <iframe src={`http://localhost:3000/embed/clickpick/${id}`} width="80%" height="100%" style={{ border: "none" }} title="Embedded Preview"></iframe> */}
                <iframe src={`https://qnarweb.com/embed/clickpick/${id}`} width="80%" height="100%" style={{ border: "none" }} title="Embedded Preview"></iframe>
              </div>

              {/* HTML Code */}
              <div style={codeStyle}>
                <h4>Embed Code:</h4>
                <textarea
                  value={embedCode}
                  readOnly
                  rows="4"
                  style={textareaStyle}
                />
                <button onClick={handleCopyEmbedCode} style={copyButtonStyle}>
                  Copy Code
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      </>}
      </>
  );
}


const overlayStyle = {
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: 10001,
  backgroundColor: "rgba(0, 0, 0, 0.5)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const popupStyle = {
  backgroundColor: "white",
  padding: "20px",
  borderRadius: "12px",
  width: "90%",
  height: "90%",
  maxWidth: "1200px",
  maxHeight: "90vh",
  display: "flex",
  flexDirection: "column",
  position: "relative",
  boxShadow: "0 4px 10px ",
  overflow: "hidden",
};

const closeButtonStyle = {
  background: "transparent",
  border: "none",
  fontSize: "24px",
  position: "absolute",
  top: "10px",
  right: "10px",
  cursor: "pointer",
  color: "#333",
};

const contentStyle = {
  display: "flex",
  flexDirection: "column",
  height: "100%",
  gap: "10px",
};

const previewStyle = {
  flex: "0 0 70%", // Take up available space
  overflow: "hidden",
  display: "flex",
  justifyContent: "center",  // Centers the content horizontally
  alignItems: "center",
  borderRadius: "8px",
};

const codeStyle = {
  flex: "0 0 30%", // Take up less space compared to the preview
  padding: "10px",
 
  backgroundColor: "#f9f9f9",
  borderRadius: "8px",
};

const textareaStyle = {
  width: "100%",
  height: "30%",
  padding: "10px",
  borderRadius: "6px",
  border: "1px solid #ccc",
  fontSize: "14px",
  resize: "none",
  backgroundColor: "#fafafa",
  boxShadow: "inset 0 1px 3px rgba(0, 0, 0, 0.1)",
};

const copyButtonStyle = {
  padding: "10px 15px",
  backgroundColor: "#4CAF50",
  color: "white",
  border: "none",
  borderRadius: "6px",
  cursor: "pointer",
  fontSize: "14px",
  fontWeight: "bold",
  transition: "background-color 0.3s",
  width: "fit-content",
  marginTop: "10px",
};

copyButtonStyle["&:hover"] = {
  backgroundColor: "#0056b3",
};

// Optional media queries for further responsiveness
const mediaQueryStyles = `
  @media (max-width: 768px) {
    ${popupStyle}: {
      width: 95%;
      height: 85%;
    },
    ${contentStyle}: {
      flexDirection: "column",
      gap: "20px",
    },
    ${codeStyle}: {
      flex: 0.4,
    },
  }

  @media (max-width: 480px) {
    ${popupStyle}: {
      padding: 15px,
    },
    ${textareaStyle}: {
      fontSize: "12px",
    },
    ${copyButtonStyle}: {
      fontSize: "12px",
    },
  }
`;

export default ClickPick;
