import React from "react";
import { Box, Typography, Button } from "@mui/material";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import backBottom from "../../Assets/lowerbg.png";
import celebrateBubbles from "../../Assets/celebration.gif";
import max from '../../Assets/maximize.png'
import min from '../../Assets/minimize.png'
// Props allow reusability for different scores, titles, or actions.
const CelebrationScreen = ({ score, onReplay,isFullscreen, handleFullScreen }) => {
  
  return (
    <Box
      sx={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(255, 255, 255, 0.9)",
        zIndex: 999,
        display: "flex",
        flexDirection: "column",
        
        alignItems: "center",
      }}
    >
        <Box sx={{marginTop:"10vh"}}>
      {/* Celebration Title */}
      <Typography
        variant="h1"
        sx={{
          color: "#4B7857",
          textAlign: "center",
          marginBottom: "20px",
          direction: "ltr",
          fontWeight: "bold",
          fontSize: { xs: '3rem', sm: '3rem', md: '6rem' },
          zIndex: 1001,
        }}
      >
        Great Job!
      </Typography>

      {/* Score */}
      <Typography
        variant="h3"
        sx={{
          color: "#4B7857",
          marginBottom: "40px",
          direction: "rtl",
          zIndex: 1001,
        }}
      >
        Score: <span style={{ color: "#EB8576" }}>{score}</span>
      </Typography>

      {/* Replay Button */}
      <Button
        variant="contained"
        sx={{
          backgroundColor: "#4B7857",
          color: "#fff",
          fontSize: "1.5rem",
          padding: "10px 20px",
          "&:hover": {
            backgroundColor: "grey",
          },
          zIndex: 1001,
        }}
        onClick={onReplay}
      >
        Play again
        <PlayArrowIcon />
      </Button>
      </Box>
      {/* Background and Celebratory Effects */}
      <Box
        component="img"
        src={backBottom}
        alt="Background"
        sx={{
          width: "100%",
          height: "30%",
          position: "absolute",
          bottom: 0,
          zIndex: 1,
        }}
      />
         <Box
                sx={{
                  position: 'absolute',
                  bottom:  '0',
                  width: '90%',
                  
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  padding: 2,
                  zIndex: 2,
                  gap: '10px',
                }}
              >
         <img
            src={isFullscreen ? min : max}
            alt="Maximize"
            style={{
              maxWidth: '60px',
              maxHeight: '60px',
              width: 'clamp(20px, 8vw, 60px)',
              height: 'clamp(20px, 8vw, 60px)',
              cursor: 'pointer',
            }}
            onClick={handleFullScreen}
          />
          </Box>
        
      <Box
        component="img"
        src={celebrateBubbles}
        alt="Celebration Left"
        sx={{
          position: "absolute",
          bottom: { xs: "30vh", lg: '20vh' },
          left: { xs: "-20vw",md:"-25vw" ,lg: '-20vw' },
          width: { xs: '90%', lg: '70%' },
          height: "auto",
          zIndex: 2,
        }}
      />
      <Box
        component="img"
        src={celebrateBubbles}
        alt="Celebration Right"
        sx={{
          position: "absolute",
          bottom: { xs: "30vh", lg: '20vh' },
          right: { xs: "-20vw",md:"-25vw" ,lg: '-20vw' },
          transform: "scaleX(-1)", // Flips the image horizontally

          width: { xs: '90%', lg: '70%' },
          height: "auto",
          zIndex: 2,
        }}
      />
    </Box>
  );
};

export default CelebrationScreen;
