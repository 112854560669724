import React, { useState, useEffect } from 'react';
import { Box, Grid, Typography, Button, TextField, Paper } from "@mui/material";
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import EditIcon from '@mui/icons-material/Edit';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import wheel from '../Assets/spinIcon.png'
import ClickPick from '../Assets/clickpick2.png'
import { useTranslation } from 'react-i18next';
import { useLocation,useRoute } from 'wouter';
import Olive from '../Assets/Olives.png'


function GameChoose({game}) {
  const [match, params] = useRoute('/GameSelect/:id'); // Use the same route pattern
  const { id } = params; // Get the id from params
    const {t, i18n} = useTranslation();
    const [location, setLocation] = useLocation();
    const [games, setGames] = useState([{}]);

    useEffect(() => {
        // Fetch all games when the component mounts
        GetGames();
    }, []);

    const GetGames = async () => {
        try {
          const response = await fetch(`https://qnar.fun/api/games/type/${id}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
          });
          const data = await response.json();
          setGames(data);
          return data; // Return the game ID for creating questions
        } catch (error) {
          console.error('Error posting game:', error);
        }
    };
  return (
 
    <div style={{ backgroundColor: "#F9F9ED", color: "#4B7857", minHeight: "100vh" }}>
      {/* Hero Section */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: "40px",
          background: `linear-gradient(rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7)), url(${Olive})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <Grid container spacing={3} alignItems="center"  justifyContent= "center">
          {/* Left Section: Image and Description */}
          <Grid item xs={12} md={8}>
            <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, gap: 3,  alignItems: { xs: "center", md: "flex-start" }, // Center on xs, align left on md
          textAlign: { xs: "center", md: "left" },  }}>
              <Box
                component="img"
                src={ClickPick}
                alt="ClickPick"
                sx={{
                  width: { xs: "50%", md: "30%" },
                  height: "auto",
                  borderRadius: 2,
                  //boxShadow: 3,
                }}
              />
              <Box>
                <Typography variant="h3" sx={{ fontWeight: "bold", marginBottom: 2 }}>
                  {id == 2 ? t("ClickPickTitle") : t("SpinningH1")}
                </Typography>
                <Typography variant="h6" sx={{ color: "text.secondary" }}>
                  {id == 2 ? t("ClickPickDesc") : t("SpinningH2")}
                </Typography>
              </Box>
            </Box>
          </Grid>
  
          {/* Right Section: Buttons */}
          <Grid item xs={12} md={4} sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#4B7857",
                color: "#fff",
                fontWeight: "bold",
                borderRadius: 2,
                padding: "10px 20px",
                "&:hover": { backgroundColor: "#3A6040" },
              }}
            >
              Create New Activity
            </Button>
            <Button
              variant="outlined"
              sx={{
                color: "#E6E444",
                borderColor: "#E6E444",
                fontWeight: "bold",
                borderRadius: 2,
                padding: "10px 20px",
                "&:hover": { backgroundColor: "#FDF6C7" },
              }}
            >
              Upgrade
            </Button>
          </Grid>
        </Grid>
      </Box>
  
      {/* Search Section */}
      <Box sx={{ padding: "40px 20px", textAlign: "center" }}>
        <Typography variant="h4" sx={{ fontWeight: "bold", marginBottom: 3 }}>
          Search in our community
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            justifyContent: "center",
            alignItems: "center",
            gap: 2,
            maxWidth: 500,
            margin: "0 auto",
          }}
        >
          <TextField
            variant="outlined"
            placeholder="Search"
            fullWidth
            sx={{ backgroundColor: "#fff", borderRadius: 2 }}
          />
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#3B5D44",
              color: "#fff",
              fontWeight: "bold",
              padding: "10px 20px",
              "&:hover": { backgroundColor: "#2E4633" },
            }}
          >
            Search
          </Button>
        </Box>
      </Box>
  
      {/* Games Section */}
      <Paper sx={{ padding: "20px", margin: "20px",backgroundColor: "#fff", }} elevation={2}>
  <Grid container spacing={2}> {/* Adjust spacing for better gap between cards */}
    {games.map((item, idx) => (
      <Grid item key={idx} xs={12} sm={6} md={3} container
      spacing={2}
      justifyContent="center" // Horizontally center the grid items
       > 
        <Paper
          elevation={4}
          sx={{
            mb: 2,
            width: "90%",
            padding: "16px",
            borderRadius: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
            justifyContent: "space-between",
            height: "300px", // Adjust card height to make it smaller
            cursor: "pointer",
            transition: "transform 0.3s ease, box-shadow 0.3s ease",
            "&:hover": {
              transform: "translateY(-5px)",
              boxShadow: 6,
            },
          }}
          onClick={() => setLocation(`/play/clickpick/${item.id}`)}
        >
          <Box
            sx={{
              width: "60%", // Adjust image size relative to the card
              height: 0,
              paddingTop: { xs: "40%", sm: "60%", lg: "50%" },
              backgroundImage: `url(${id == 2 ? ClickPick : wheel})`, // Use backticks for dynamic URLs
              backgroundSize: "contain",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              borderRadius: 1,
            }}
          />
          <Box sx={{ flexGrow: 1, paddingTop: 2,  width: "100%" }}>
            <Typography
              variant="h6" // Smaller typography variant
              sx={{
                fontWeight: "bold",
                color: "#4B7857",
                whiteSpace: "normal",
      
                overflow: "hidden", // Prevent hiding text
                wordWrap: "break-word",
              }}
            >
              {item.title}
            </Typography>
            <Typography variant="body2"   sx={{
    color: "#6B6B6B",
    marginTop: 1,
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitLineClamp: 2, // Limit to two lines
    WebkitBoxOrient: "vertical", // Required for WebkitLineClamp
    wordBreak: "break-word", // Allow breaking long words to fit width
    width: "100%",
  }}>
              {item.description}
            </Typography>
            <Typography variant="body2" sx={{ color: "text.secondary", marginTop: 1, display: "-webkit-box",
              WebkitLineClamp: 3, // Limit description to 3 lines
              WebkitBoxOrient: "vertical",}}>
              Created by {item.creator_username}
            </Typography>
          </Box>
        </Paper>
      </Grid>
    ))}
  </Grid>
</Paper>
    </div>
  );
}

export default GameChoose