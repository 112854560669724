import React , { useState, useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";
import { useLocation } from 'wouter';
const Layout = ({ children }) => {
  const headerHeight = 64; // Adjust to your actual header height in pixels
  const [isGame, setIsGame] = useState(false);
  const [location, setLocation] = useLocation();
  const [isEmbedded, setIsEmbedded] = useState(false);
  
 useEffect(() => {

    const currentURL = window.location.href;
    // Check if it contains the word "play"
    if (currentURL.includes("play")) {
      setIsGame(true);      // Do something here
    }
    else{
      setIsGame(false)
    }
  }, [location]);

  useEffect(() => {
    const currentURL = window.location.href;

      // Check if it's an embedded link
      if (currentURL.includes("embed")) {
        setIsEmbedded(true);
      } else {
        setIsEmbedded(false);
      }
  
      // Check if it contains the word "play"
      if (currentURL.includes("play")) {
        setIsGame(true);
      } else {
        setIsGame(false);
      }
    }, [window.location.href]);
  
    // Render without layout if it's an embedded link
    if (isEmbedded) {
      return <>{children}</>;
    }
  return (
    <div>
      <Header />
      <div style={{ marginTop: isGame?  'auto': `${headerHeight}px` }}>
        {children}
      </div>
      <Footer />
    </div>
  );
};

export default Layout;