import React, { useState } from 'react';
import { Box, Button, TextField, Typography,  Grid, Link, Snackbar, Alert, Dialog } from '@mui/material';
import axios from 'axios';
import GoogleIcon from "../../Assets/logos/google.png";
import MicrosoftIcon from "../../Assets/logos/microsoft.png";
import FacebookIcon from "../../Assets/logos/facebook.png";
import AppleIcon from "../../Assets/logos/apple.png";
import { useLocation } from 'wouter';
import pic from "../../Assets/signin.jpeg"

const Signup = ({ open, onClose, onSwitch }) => {
  const [Email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState('');
  const [location, setLocation] = useLocation();


  const handleRegister = async () => {

    if (password !== confirmPassword) {
      alert('Passwords do not match!');
      return;
    }
    const payload = {
      email: Email, // Replace with your email state variable
      password: password, // Replace with your password state variable
    };
  
    try {
      const response = await axios.post("https://qnar.fun/api/register/", payload, {
        headers: {
          "Content-Type": "application/json", // Optional: Axios sets this automatically for JSON payloads
        },
      });
  
      console.log("Registration successful:", response.data);

    
      // Save user data to localStorage
      localStorage.setItem('username', response.data.username);
      localStorage.setItem('first_name', response.data.first_name); // Save first name
      localStorage.setItem('access_token', response.data.access);
      setLocation('/dashboard');
      window.location.reload();
      // Handle success (e.g., redirect to login or display success message)
    } catch (error) {
      if (error.response) {
        const serverErrors = error.response.data; // Extract server response
      if (serverErrors.email) {
        // Check if there's an email-specific error
        alert(serverErrors.email[0]); // Display the email error message
      }
      } else if (error.request) {
        // The request was made, but no response was received
        console.error("No response received:", error.request);
      } else {
        // Something happened in setting up the request
        console.error("Error setting up request:", error.message);
      }
    }
  };
  return (
<Dialog 
  open={open} 
  onClose={onClose} 
  maxWidth="lg" 
  fullWidth
  sx={{
    '& .MuiDialog-paper': {
      borderRadius: 8,
      maxHeight: '90vh', // Ensure the dialog respects the viewport height
      overflow: 'hidden', // Prevent content from spilling
    },
  }}
>
  <Grid 
    container 
    sx={{
      height: { xs: "auto", md: "100%" },
      flexWrap: "wrap",
      alignItems: "center",
    }}
  >
    {/* Form Section */}
    <Grid
      item
      xs={12}
      md={6}
      sx={{
        padding: { xs: 2, md: 4 },
        borderRadius: { xs: 0, md: 8 },
        backgroundColor: "#fff",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        height: { xs: "auto", md: "100%" },
        overflow: "auto", // Allow content to scroll
      }}
    >
      <Box sx={{ maxHeight: "80vh", overflow: "auto", paddingBottom: 2 }}>
        <Typography variant="h4" align="center" sx={{ mb: 3, color: "#5B945B" }}>
          Register with Qnar
        </Typography>
        <Grid container spacing={2}>
          {/* Email Field */}
          <Grid item xs={12}>
            <TextField
              fullWidth
              placeholder="Email"
              value={Email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Grid>
          {/* Password Field */}
         
           <Grid item xs={6}>
            <TextField
              fullWidth
              placeholder="Password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              placeholder="confirm Password"
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            </Grid>
        </Grid>
        {/* <Typography sx={{ textAlign: "center", margin: "1rem 0", color: "#5B945B" }}>
          or continue with
        </Typography>
        <Button
          variant="outlined"
          startIcon={<img src={GoogleIcon} alt="Google" style={{ width: "20px" }} />}
          sx={{ width: "100%", mb: 1, textTransform: "none" }}
        >
          Continue with Google
        </Button>
        <Button
          variant="outlined"
          startIcon={<img src={MicrosoftIcon} alt="Microsoft" style={{ width: "20px" }} />}
          sx={{ width: "100%", mb: 1, textTransform: "none" }}
        >
          Continue with Microsoft
        </Button>
        <Button
          variant="outlined"
          startIcon={<img src={FacebookIcon} alt="Facebook" style={{ width: "20px" }} />}
          sx={{ width: "100%", mb: 1, textTransform: "none" }}
        >
          Continue with Facebook
        </Button> */}
        <Button
          variant="contained"
          onClick={handleRegister}
          sx={{
            width: "100%",
            backgroundColor: "#5B945B",
            color: "white",
            mb: 1,
            mt: 2,
            "&:hover": { backgroundColor: "#4A7A4A" },
          }}
        >
          Sign up
        </Button>
        <Typography variant="body2" sx={{ textAlign: "center" }}>
          Already have an account?{" "}
          <Link onClick={onSwitch} style={{cursor: 'pointer'}} underline="hover">
            Log in
          </Link>
        </Typography>
        </Box>
      </Grid>

     {/* Image Section */}
       <Grid
         item
         xs={12}
         md={6}
         sx={{
           textAlign: "center",
           display: "flex",
           flexDirection: "column",
           justifyContent: "center",
           alignItems: "center",
           padding: { xs: 2, md: 4 },display: { xs: "none", md: "block" }
         }}
       >
         <img
           src={pic}
           alt="Online Learning"
           style={{
             width: "100%",
             maxHeight: "60vh",
             borderRadius: "8px",
             objectFit: "cover",
             marginBottom: "1rem",
           }}
         />
         <Typography
           variant="h5"
           sx={{
             color: "#5B945B",
             fontWeight: "bold",
             textAlign: "center",
             marginTop: "1rem",
           }}
         >
           من سلك طريقاً يلتمسُ فيه علماً، سهَّل اللهُ له طريقاً إلى الجنَّة
         </Typography>
       </Grid>
     </Grid>
   </Dialog>
  );
};




export default Signup;
