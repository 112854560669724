import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { jwtDecode } from "jwt-decode";
import {
  AppBar, Toolbar, Button, Typography, Box, IconButton, Menu, MenuItem, Container, FormControl, Select, MenuItem as MuiMenuItem
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import LanguageIcon from '@mui/icons-material/Language';
import { useTheme } from '@mui/material/styles';
import { useLocation } from 'wouter';
import { useMediaQuery } from '@mui/material'; 
import logo from '../../Assets/Logo2.png';
import CloseIcon from '@mui/icons-material/Close';
import Globe from '../../Assets/globe.png';
import { useTranslation } from 'react-i18next';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Login from './Login';
import Regist from './Signup';
function Header() {
  const [location, setLocation] = useLocation();
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElLang, setAnchorElLang] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false); // Change this based on your auth logic
  const [username, setUsername] = useState('');
  const [isGame, setIsGame] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { t, i18n } = useTranslation();
  const isStaticRoute =  location.startsWith("/play");
  const [openLogin, setOpenLogin] = useState(false);
  const [openRegist, setOpenRegist] = useState(false);

  
  const handleOpenLogin = () => {
    setOpenRegist(false);
    setOpenLogin(true);
  };

  const handleCloseLogin = () => {
    setOpenLogin(false);
  };
  const handleOpenRegist = () => {
    setOpenLogin(false);
    setOpenRegist(true);
  };

  const handleCloseRegist = () => {
    setOpenRegist(false);
  };
  const handleChange = (event) => {
    setAnchorElNav(null);
    const selectedLanguage = event.target.value;
    i18n.changeLanguage(selectedLanguage);
    localStorage.setItem('i18nextLng', selectedLanguage); // Persist in localStorage
    
  };

  const handleChange1 = (event) => {
    setAnchorElNav(null);
    const chosen = event.target.value;
    if (chosen == "profile"){
      setLocation("/profile");
    }
    else if(chosen == "plan"){
      setLocation("/pricing");
    }
    else if(chosen == "logout"){
      handleLogout();
    }
    
  };
  /////////////////////////////////////////////////// check login token ////////////////////////////////////////
  useEffect(() => {
    // Example of getting auth status from localStorage (or any other method)
    const checkTokenValidity = async () => {
      const token = localStorage.getItem("access_token");

      if (token) {
        const isTokenValid = !isTokenExpired(token);

        if (isTokenValid) {
          setIsLoggedIn(true);
          setUsername(localStorage.getItem("username") || "");
        } else {
          // Token expired, try refreshing it
          const refreshed = await refreshToken();
          if (!refreshed) {
            logout();
          }
        }
      }
    };

    checkTokenValidity();
    const currentURL = window.location.href;
    // Check if it contains the word "play"
    if (currentURL.includes("play")) {
      setIsGame(true);      // Do something here
    }
    else{
      setIsGame(false)
    }
  }, [location]);
  const isTokenExpired = (token) => {
    try {
      const decoded = jwtDecode(token);
      const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
      return decoded.exp < currentTime; // Check if token is expired
    } catch (error) {
      console.error("Failed to decode token:", error);
      return true; // Assume token is invalid if it can't be decoded
    }
  };

  const refreshToken = async () => {
    try {
      const refreshToken = localStorage.getItem("refresh_token");
      if (!refreshToken) {
        return false; // No refresh token available
      }

      const response = await axios.post(`https://qnar.fun/api/token/refresh/`, {
        refresh: refreshToken,
      });

      if (response.data.access) {
        localStorage.setItem("access_token", response.data.access);
        return true; // Token refreshed successfully
      }
    } catch (error) {
      console.error("Failed to refresh token:", error);
      return false;
    }
  };

  const logout = () => {
    // Clear localStorage and reset state
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("username");
    localStorage.removeItem("first_name");
    setIsLoggedIn(false);
    setUsername("");
  };

/////////////////////////////////////////////////// end token ////////////////////////////////////////
  const handleOpenNavMenu = (event) => {
    if(anchorElNav == null){
      setAnchorElNav(event.currentTarget);}
    else{
      setAnchorElNav(null);
    }
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleOpenLangMenu = (event) => {
    setAnchorElLang(event.currentTarget);
  };

  // const handleCloseLangMenu = () => {
  //   setAnchorElLang(null);
  // };

  const handleLogout = () => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('username');
    setIsLoggedIn(false);
    setUsername('');
    setLocation('/'); // Redirect to login page
  };

  return (
    <AppBar position={isGame? "static":"fixed"} style={{zIndex:1000, background: '#fff', height: 'auto', fontWeight: 'bold' }}>
      <Container>
        <Toolbar disableGutters>
          <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1, fontWeight:'bold' }}>
          <Login open={openLogin} onClose={handleCloseLogin} onSwitch={handleOpenRegist} />
          <Regist open={openRegist} onClose={handleCloseRegist} onSwitch={handleOpenLogin}/>

            <img
              onClick={() => setLocation('/')}
              style={{ cursor: 'pointer', }}
              src={logo}
              width="150px"
              height="auto"
              alt="Logo"
            />
{/* marginRight: isMobile ? 'auto' : '0px' */}
            {!isMobile && (
              <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center' }}>
                {isLoggedIn?(<>
                <Button onClick={() =>setLocation('/dashboard')} sx={{  color: location === '/dashboard' ? '#EB8576' : '#3B5D44', textDecoration:location === '/dashboard' ?'underline': '', fontWeight:'bold'}}>
                {t('Dashboard')}
                </Button>
                {/* <Button onClick={() => setLocation('/myresults')} sx={{ color: location === '/myresults' ? '#EB8576' : '#3B5D44' ,textDecoration:location === '/myresults' ?'underline': '', fontWeight:'bold' }}>
                {t('My Results')}
                </Button> */}
                <Button onClick={() => setLocation('/games')} sx={{ color: location === '/games' ? '#EB8576' : '#3B5D44' ,textDecoration:location === '/games' ?'underline': '', fontWeight:'bold' }}>
                {t('Games')}
                </Button></>):(
                  <>
                    <Button onClick={() =>setLocation('/')} sx={{  color: location === '/' ? '#EB8576' : '#3B5D44' ,textDecoration:location === '/' ?'underline': '', fontWeight:'bold'}}>
                    {t('Home')}
                    </Button>
                    <Button onClick={() => setLocation('/about')} sx={{ color: location === '/about' ? '#EB8576' : '#3B5D44' ,textDecoration:location === '/about' ?'underline': '', fontWeight:'bold' }}>
                    {t('AboutUs')}
                    </Button>
                    <Button onClick={() => setLocation('/games')} sx={{ color: location === '/Games' ? '#EB8576' : '#3B5D44' ,textDecoration:location === '/Games' ?'underline': '', fontWeight:'bold' }}>
                    {t('Games')}
                    </Button>
                    </>)}

                <IconButton
              size="large"
              aria-label="language"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenLangMenu}
              color="inherit"
              zIndex={0}
            >
        
            </IconButton>
            <FormControl sx={{ minWidth: 120 }}>
              <Select
                defaultValue="English"
                displayEmpty
                value={i18n.language}
                onChange={handleChange}
                sx={{ color: '#3B5D44', height:'5vh', fontWeight:'bold', bordercolor:'#3B5D44' }}
                renderValue={(selected) => (
                  <Box display="flex" alignItems="center">
                    <Box
                      component="img"
                      src={Globe}
                      alt="Custom"
                      width={24}
                      height={24}
                      marginRight={1}
                      zIndex={0}
                    />
                     {selected === 'ar' ? 'العربية' : 'English'}
                  </Box>)}
              >             
                <MuiMenuItem sx={{ color: '#3B5D44' }} value="en">English</MuiMenuItem>
                <MuiMenuItem sx={{ color: '#3B5D44' }} value="ar">العربية</MuiMenuItem>
              </Select>
             
            </FormControl>
            
              </Box>
            )}

{isMobile && (
        
        <Box 
sx={{ 
display: 'flex', 
justifyContent: 'center', // Center items horizontally
alignItems: 'center',    // Center items vertically if needed
margin: '0 auto'         // Ensure it's centered within its parent
}}
>
  {isLoggedIn? <>
    <FormControl sx={{ minWidth: 120 }}>
  <Select
    value={i18n.language}
    onChange={handleChange1}
    displayEmpty
    sx={{
      color: '#3B5D44',
      height: '5vh',
      fontWeight: 'bold',
      borderColor: '#3B5D44',
    }}
    renderValue={(selected) => (
      <Box display="flex" alignItems="center">
        <AccountCircleIcon 
          sx={{ width: 24, height: 24, marginRight: 1,  }}
        />
        <Box display="flex">
          <Box sx={{ marginRight: 1 }}>{username}</Box>
         
        </Box>
      </Box>
    )}
  >
    <MuiMenuItem sx={{ color: '#3B5D44' }} value="profile">my profile</MuiMenuItem>
    <MuiMenuItem sx={{ color: '#3B5D44' }} value="plan">my plan</MuiMenuItem>
    <MuiMenuItem sx={{ color: '#3B5D44' }} value="logout">logout</MuiMenuItem>
  </Select>
</FormControl>
</> :
          <>
                    <Button onClick={handleOpenLogin}  variant="contained" sx={{backgroundColor: '#4B7857', color: '#fff', marginRight: '10px' }}>
          {t('Login')}
          </Button>

         
          </>}
</Box>)}
<Box sx={{ position: 'relative', zIndex: 10001 }}>

            {isMobile && (
  
              <IconButton
                size="large"
                aria-label="menu"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
                
                sx={{marginLeft: i18n.language === 'en' ? 'auto' : 0,  // Align to the left for English
                  marginRight: i18n.language === 'ar' ? 'auto' : 0   // Align to the right for Arabic
                }}
              >
                {anchorElNav ? (
          <CloseIcon sx={{ color: '#3B5D44',   }} />
        ) : (
          <MenuIcon sx={{ color: '#3B5D44' }} />
        )}
              </IconButton>
       
            )}
            
          </Box>

          <Box sx={{ display: 'flex', alignItems: 'center', zIndex: 0  }}>
            {!isMobile && isLoggedIn && (
              <>
<FormControl sx={{ minWidth: 120 }}>
  <Select
    value={i18n.language}
    onChange={handleChange1}
    displayEmpty
    sx={{
      color: '#3B5D44',
      height: '5vh',
      fontWeight: 'bold',
      borderColor: '#3B5D44',
    }}
    renderValue={(selected) => (
      <Box display="flex" alignItems="center">
        <AccountCircleIcon 
          sx={{ width: 24, height: 24, marginRight: 1 }}
        />
        <Box display="flex">
          <Box sx={{ marginRight: 1 }}>{username}</Box>
         
        </Box>
      </Box>
    )}
  >
    <MuiMenuItem sx={{ color: '#3B5D44' }} value="profile">my profile</MuiMenuItem>
    <MuiMenuItem sx={{ color: '#3B5D44' }} value="plan">my plan</MuiMenuItem>
    <MuiMenuItem sx={{ color: '#3B5D44' }} value="logout">logout</MuiMenuItem>
  </Select>
</FormControl>

              </>
            )}

            {!isMobile && !isLoggedIn && (
              <>
                <Button onClick={handleOpenLogin}  variant="outlined" sx={{backgroundColor: '#4B7857', color: '#fff', marginRight: '10px' }}>
                {t('Login')}
                </Button>
                <Button onClick={handleOpenRegist} variant="outlined" sx={{ borderColor: '#4B7857', color: '#4B7857',marginRight: '10px'}}>
                {t('signup')}
                </Button>
              </>
            )}  
          </Box>

          <Menu
            anchorEl={anchorElNav}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            open={Boolean(anchorElNav)}
            onClose={handleCloseNavMenu}
            slotProps={{
              paper: {
       
                sx: {
                  width: '80%',       // Adjust width for menu with margin
                  height: '100vh', 
                  minHeight: '100vh',    // Full height of the viewport
                  position: 'fixed',
                  marginTop:'-4vw',
                  marginLeft: i18n.language=== 'ar' ?'-10vw':'10vw'  ,           // Align to the top
                  zIndex:10000,       // Ensure the menu is on top
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  bgcolor: 'background.paper',
                  '& .MuiMenuItem-root': {
                    
                    borderBottom: '1px solid #3B5D44', // Line under each item
                    padding: '16px 20px',
                   
                  },
                },
              },
            }}
          >
            {isLoggedIn?<>
            <MenuItem sx={{ color: '#3B5D44' }} onClick={() => { handleCloseNavMenu();setLocation('/dashboard')}}>{t('dashboard')}</MenuItem>
            {/* <MenuItem sx={{ color: '#3B5D44' }} onClick={() => {handleCloseNavMenu(); setLocation('/about')}}>{t('AboutUs')}</MenuItem> */}
            <MenuItem sx={{ color: '#3B5D44' }} onClick={() => {handleCloseNavMenu(); setLocation('/games')}}>{t('Games')}</MenuItem>
            </>:<>
            <MenuItem sx={{ color: '#3B5D44' }} onClick={() => { handleCloseNavMenu(); setLocation('/')}}>{t('Home')}</MenuItem>
            <MenuItem sx={{ color: '#3B5D44' }} onClick={() => {handleCloseNavMenu(); setLocation('/about')}}>{t('AboutUs')}</MenuItem>
            <MenuItem sx={{ color: '#3B5D44' }} onClick={() => {handleCloseNavMenu(); setLocation('/games')}}>{t('Games')}</MenuItem>
            </>}
            <MenuItem>
                <IconButton size="large" aria-label="language"  aria-controls="menu-appbar" aria-haspopup="true" onClick={handleOpenLangMenu} 
      style={{ backgroundColor: '#fff', borderRadius: '50%' }} > <LanguageIcon /></IconButton>
              
                <FormControl >
                  <Select
                    defaultValue="English"
                    displayEmpty
                    value={i18n.language}
                    onChange={handleChange}
                    sx={{ color: '#3B5D44' }}
                    renderValue={(selected) => (
                      <Box display="flex" alignItems="center">
                        <Box
                          component="img"
                          src={Globe}
                          alt="Custom"
                          width={24}
                          height={24}
                          marginRight={1}
                        />
                         {selected === 'ar' ? 'العربية' : 'English'}
                      </Box>)}
                  >
                    <MuiMenuItem sx={{ color: '#3B5D44' }} value="en">English</MuiMenuItem>
                    <MuiMenuItem sx={{ color: '#3B5D44' }} value="ar">العربية</MuiMenuItem>
                  </Select>
                </FormControl>
            </MenuItem>
          </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default Header;
