import React, { useEffect } from "react";

const Loading = ({ message }) => {
  const styles = {
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundColor: "rgba(255, 255, 255, 0.8)", // Transparent white background
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      zIndex: 1000, // Ensure it appears above other content
    },
    ball: {
      width: "30px",
      height: "30px",
      margin: "10px",
      borderRadius: "50%",
      backgroundColor: "#F4DADA", // Playful red
      animation: "bounce 1.5s infinite ease-in-out",
    },
    text: {
      marginTop: "15px",
      fontSize: "1.2rem",
      color: "#333",
      fontFamily: "'Comic Sans MS', 'Comic Sans', cursive", // Fun font
    },
  };

  useEffect(() => {
    // Create a <style> tag
    const styleTag = document.createElement("style");
    styleTag.type = "text/css";
    styleTag.textContent = `
      @keyframes bounce {
        0%, 80%, 100% {
          transform: scale(0.8);
        }
        40% {
          transform: scale(1.2);
        }
      }
    `;
    // Append the <style> tag to the document head
    document.head.appendChild(styleTag);

    // Cleanup on component unmount
    return () => {
      document.head.removeChild(styleTag);
    };
  }, []);

  return (
    <div style={styles.overlay}>

        <div style={styles.ball}></div>
        <div style={styles.text}>{message}</div>

    </div>
  );
};

export default Loading;
