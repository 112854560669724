import React, { useEffect, useState } from 'react';

const ScoreName = ({ onSubmit, scores }) => {
  const [guestName, setGuestName] = useState('');

  const styles = {
    overlay: {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundColor: "rgba(0, 0, 0, 0.5)", // Dark transparent background
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      zIndex: 1000,
    },
    popup: {
      backgroundColor: "#fff",
      padding: "20px",
      borderRadius: "10px",
      boxShadow: "0 4px 10px rgba(0, 0, 0, 0.3)",
      textAlign: "center",
      width: "300px",
    },
    input: {
      width: "80%",
      padding: "10px",
      margin: "10px 0",
      borderRadius: "5px",
      border: "1px solid #ccc",
      fontSize: "16px",
    },

    button: {
      padding: "10px 20px",
      border: "none",
      borderRadius: "5px",
      cursor: "pointer",
    },
    submitButton: {
      backgroundColor: "#3B5D44",
      color: "#fff",
    },

  };

  const handleSubmit = () => {
    

    if (guestName.trim()) {
      onSubmit(guestName);
    } else {
      alert("Name cannot be empty.");
    }
  };



  return (
    <div style={styles.overlay}>
      <div style={styles.popup}>
        <h2 style={{ color: '#3B5D44'}}>Enter Your Name</h2>
        <input
          type="text"
          placeholder="Enter your name"
          value={guestName}
          onChange={(e) => setGuestName(e.target.value)}
          style={styles.input}
        />
        <div >
          <button
            style={{ ...styles.button, ...styles.submitButton }}
            onClick={handleSubmit}
          >
            Submit
          </button>

        </div>
      </div>
    </div>
  );
};

export default ScoreName;